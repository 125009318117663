<template>
  <v-navigation-drawer v-model="drawer" :mini-variant.sync="mini" permanent app>
    <v-list-item>
      <v-btn icon @click.stop="mini = !mini">
        <v-icon>mdi-menu</v-icon>
      </v-btn>
    </v-list-item>
    <v-list dense>
      <v-list-item>
        <v-list-item-icon>
          <v-icon>mdi-home</v-icon>
        </v-list-item-icon>
        <v-list-item-title
            style="cursor: pointer"
            @click="gotoPage('/top')"
        >トップ
        </v-list-item-title
        >

      </v-list-item>
      <v-list-group>
        <template v-slot:activator>
          <v-list-item-icon>
            <v-icon>mdi-domain</v-icon>
          </v-list-item-icon>
          <v-list-item-title
              style="cursor: pointer"
          >会社管理
          </v-list-item-title>
        </template>
        <v-list-item>
          <v-list-item-icon class="ml-10">
            <v-icon>mdi-plus-thick</v-icon>
          </v-list-item-icon>
          <v-list-item-title
              style="cursor: pointer"
              @click="gotoPage('/addCompany')"
          >会社新規
          </v-list-item-title>
        </v-list-item>
        <v-list-item>
          <v-list-item-icon class="ml-10">
            <v-icon>mdi-format-list-bulleted</v-icon>
          </v-list-item-icon>
          <v-list-item-title
              style="cursor: pointer"
              @click="gotoPage('/companyView')"
          >会社一覧
          </v-list-item-title>
        </v-list-item>
      </v-list-group>
      <v-list-group>
        <template v-slot:activator>
          <v-list-item-icon>
            <v-icon>mdi-file-tree-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-title
              style="cursor: pointer"
          >商品管理
          </v-list-item-title>
        </template>
        <v-list-item>
          <v-list-item-icon class="ml-10">
            <v-icon>mdi-plus-thick</v-icon>
          </v-list-item-icon>
          <v-list-item-title
              style="cursor: pointer"
              @click="gotoPage('/addProduct')"
          >商品新規
          </v-list-item-title>
        </v-list-item>
        <v-list-item>
          <v-list-item-icon class="ml-10">
            <v-icon>mdi-format-list-bulleted</v-icon>
          </v-list-item-icon>
          <v-list-item-title
              style="cursor: pointer"
              @click="gotoPage('/goodView')"
          >商品一覧
          </v-list-item-title>
        </v-list-item>
        <v-list-item>
          <v-list-item-icon class="ml-10">
            <v-icon>mdi-file-import-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-title
              style="cursor: pointer"
              @click="gotoPage('/importGoodCSV')"
          >商品CSV導入
          </v-list-item-title>
        </v-list-item>
      </v-list-group>
      <!--      <v-list-group>-->
      <!--        <template v-slot:activator>-->
      <!--          <v-list-item-icon>-->
      <!--            <v-icon>mdi-alpha-c-circle-outline</v-icon>-->
      <!--          </v-list-item-icon>-->
      <!--          <v-list-item-title-->
      <!--              style="cursor: pointer"-->
      <!--          >カテゴリ管理-->
      <!--          </v-list-item-title>-->
      <!--        </template>-->
      <!--        <v-list-item>-->
      <!--          <v-list-item-icon class="ml-10">-->
      <!--            <v-icon>mdi-format-list-bulleted</v-icon>-->
      <!--          </v-list-item-icon>-->
      <!--          <v-list-item-title-->
      <!--              style="cursor: pointer"-->
      <!--              @click="gotoPage('/categoryView')"-->
      <!--          >カテゴリ一覧-->
      <!--          </v-list-item-title>-->
      <!--        </v-list-item>-->
      <!--        <v-list-item>-->
      <!--          <v-list-item-icon class="ml-10">-->
      <!--            <v-icon>mdi-plus-thick</v-icon>-->
      <!--          </v-list-item-icon>-->
      <!--          <v-list-item-title-->
      <!--              style="cursor: pointer"-->
      <!--              @click="gotoPage('/addCategory')"-->
      <!--          >カテゴリ新規-->
      <!--          </v-list-item-title>-->
      <!--        </v-list-item>-->
      <!--      </v-list-group>-->
      <v-list-group>
        <template v-slot:activator>
          <v-list-item-icon>
            <v-icon>mdi-invoice-arrow-right</v-icon>
          </v-list-item-icon>
          <v-list-item-title
              style="cursor: pointer"
          >請求管理
          </v-list-item-title>
        </template>
        <v-list-item>
          <v-list-item-icon class="ml-10">
            <v-icon>mdi-plus-box</v-icon>
          </v-list-item-icon>
          <v-list-item-title
              style="cursor: pointer"
              @click="gotoPage('/pdfRequest')"
          >請求書登録
          </v-list-item-title>
        </v-list-item>
        <v-list-item>
          <v-list-item-icon class="ml-10">
            <v-icon>mdi-menu</v-icon>
          </v-list-item-icon>
          <v-list-item-title
              style="cursor: pointer"
              @click="gotoPage('/requestView')"
          >請求書一覧
          </v-list-item-title>
        </v-list-item>
      </v-list-group>
      <v-list-group>
        <template v-slot:activator>
          <v-list-item-icon>
            <v-icon>mdi-invoice-arrow-left</v-icon>
          </v-list-item-icon>
          <v-list-item-title
              style="cursor: pointer"
          >発注管理
          </v-list-item-title>
        </template>
        <v-list-item>
          <v-list-item-icon class="ml-10">
            <v-icon>mdi-plus-box</v-icon>
          </v-list-item-icon>
          <v-list-item-title
              style="cursor: pointer"
              @click="gotoPage('/pdfHCYU')"
          >発注書登録
          </v-list-item-title>
        </v-list-item>
        <v-list-item>
          <v-list-item-icon class="ml-10">
            <v-icon>mdi-menu</v-icon>
          </v-list-item-icon>
          <v-list-item-title
              style="cursor: pointer"
              @click="gotoPage('/hcyuView')"
          >発注書一覧
          </v-list-item-title>
        </v-list-item>
      </v-list-group>
      <v-list-group>
        <template v-slot:activator>
          <v-list-item-icon>
            <v-icon>mdi-cart-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-title
              style="cursor: pointer"
          >在庫管理
          </v-list-item-title>
        </template>
        <v-list-item>
          <v-list-item-icon class="ml-10">
            <v-icon>mdi-menu</v-icon>
          </v-list-item-icon>
          <v-list-item-title
              style="cursor: pointer"
              @click="gotoPage('/stockView')"
          >在庫一覧
          </v-list-item-title>
        </v-list-item>
      </v-list-group>
      <v-list-group>
        <template v-slot:activator>
          <v-list-item-icon>
            <v-icon>mdi-cash</v-icon>
          </v-list-item-icon>
          <v-list-item-title
              style="cursor: pointer"
          >出入金管理
          </v-list-item-title>
        </template>
        <v-list-item>
          <v-list-item-icon class="ml-10">
            <v-icon>mdi-menu</v-icon>
          </v-list-item-icon>
          <v-list-item-title
              style="cursor: pointer"
              @click="gotoPage('/moneyView')"
          >入金一覧
          </v-list-item-title>
        </v-list-item>
        <v-list-item>
          <v-list-item-icon class="ml-10">
            <v-icon>mdi-menu</v-icon>
          </v-list-item-icon>
          <v-list-item-title
              style="cursor: pointer"
              @click="gotoPage('/moneyViewHcyu')"
          >出金一覧
          </v-list-item-title>
        </v-list-item>
      </v-list-group>
    </v-list>
  </v-navigation-drawer>
</template>

<script>

export default {
  name: "SideMenu",
  data() {
    return {
      drawer: true,
      mini: true,
    };
  },
  methods: {
    //画面移動する時の判定、isPrivate(true)は二回ログイン必要な画面
    gotoPage(routePath) {
      if (this.$route.path !== routePath) {
        this.$router.push({
          path: routePath
        })
      }
    },
  }
};
</script>
