<template>
  <with-side-menu>
    <v-container fluid>
      <v-row justify="start" align="center" dense>
        <v-col cols="2">
          <h1>履歴</h1>
        </v-col>
      </v-row>
      <v-row align="center" justify="center">
        <v-col cols="12">
          商品名：{{ this.$route.params.goodName }}
        </v-col>
        <v-col cols="12">
          JANコード：{{ this.$route.params.jancode }}
        </v-col>

        <v-col cols="9">

        </v-col>
      </v-row>
      <v-row>
        <v-col v-for="(item, index) in showData" :key="index" cols="12">
          <v-card>
            <v-card-text>
              <div>操作種類: {{ showString(item.transactionType) }}</div>
              <div>操作時間: {{ item.transactionDate }}</div>
              <div>会社名: {{ item.companyName }}</div>
              <div>数量: {{ item.quantity }}</div>
              <div>操作番号: {{ item.relativeNumber }}</div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-btn
              color="mainColor"
              class="white--text button"
              style="width: 150px"
              @click="backToList()"
          >一覧に戻る
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </with-side-menu>
</template>

<script>
import WithSideMenu from "@/components/WithSideMenu";
import {searchStock} from "@/api/stock";

export default {
  name: "StockDetailShow",
  components: {
    WithSideMenu
  },
  data() {
    return {
      showData: []
    }
  },
  async mounted() {
    const response = await searchStock({
      jancode: this.$route.params.jancode
    })
    const tempResult = response.data.result;
    tempResult.sort(function (a, b) {
      return new Date(b.transactionDate) - new Date(a.transactionDate);
    });
    this.showData = tempResult
  },
  methods: {
    async backToList() {
      await this.$router.push({name: "StockView"});
    },
    showString(type){
      if(type === 0){
        return "自社在庫調整"
      }else if(type === 1){
        return "入荷"
      }else {
        return "出荷"
      }
    }
  }
}
</script>

<style scoped>

</style>