import {postWithToken} from "@/api/RestClient";
import {showDialog} from "@/utils/dialog";

export const addStock = async payload => {
    try {
        return postWithToken("author/stock/update", payload);
    } catch (error) {
        await showDialog("", error.response.data.message, "")
        throw error;
    }
};

export const searchStock = async payload => {
    try {
        return postWithToken("author/stock/getList", payload);
    } catch (error) {
        await showDialog("", error.response.data.message, "")
        throw error;
    }
};

export const updateStock = async payload => {
    try {
        return postWithToken("author/hcyu/updateStockStatus", payload);
    } catch (error) {
        await showDialog("", error.response.data.message, "")
        throw error;
    }
};

export const updateRequestStock = async payload => {
    try {
        return postWithToken("author/request/updateStockStatus", payload);
    } catch (error) {
        await showDialog("", error.response.data.message, "")
        throw error;
    }
};

