<template>
  <v-data-table
      style="width: 100%"
      dense
      :headers="headers"
      :loading="this.isLoading"
      :items="items"
      :items-per-page="20"
      item-key="id"
      :footer-props="{
      'items-per-page-options': [20, 50, 100],
      showFirstLastPage: true,
      firstIcon: 'mdi-arrow-collapse-left',
      lastIcon: 'mdi-arrow-collapse-right',
      prevIcon: 'mdi-arrow-left',
      nextIcon: 'mdi-arrow-right'
    }"
  >
    <template v-slot:body="{ items }">
      <tbody>
      <tr v-if="isLoading">
        <td></td>
      </tr>
      <tr
          v-for="item in items"
          :key="item.id"
          @dblclick="editItem(item.id)"
      >
        <td>{{ item.jancode }}</td>
        <td>{{ item.nameJp }}</td>
        <td>{{ item.categoryName }}</td>
        <td>{{ item.brandName }}</td>
        <td>{{ item.priceExcludingTax }}</td>
        <td>
          <div v-for="(itemDetail, index) in JSON.parse(item.tansportDetail)" :key="index">
            <v-row dense>
              {{ itemDetail.tanSportCompanyName }}
            </v-row>
          </div>
        </td>
        <td>
          <div v-for="(itemDetail, index) in JSON.parse(item.tansportDetail)" :key="index">
            <v-row dense>
              {{ itemDetail.tanSportPrice }}
            </v-row>
          </div>
        </td>
        <td>
          <v-icon
              small
              @click="copyDetail(item)"
          >
            mdi-content-copy
          </v-icon>
        </td>
        <td>
          <v-icon
              small
              @click="deleteItem(item.id)"
          >
            mdi-delete
          </v-icon>
        </td>
      </tr>
      </tbody>
    </template>
  </v-data-table>
</template>

<script>
import {deleteGood} from "@/api/good";

export default {
  name: "StockTable",
  props: {
    items: Array,
    isLoading: Boolean
  },
  data() {
    return {
      headers: [
        {
          "text": "JANコード",
          "value": "jancode",
          "class": "tableHeader white--text",
        },
        {
          "text": "商品名(日)",
          "value": "goodname",
          "align": "start",
          "class": "tableHeader white--text"
        },
        {
          "text": "カテゴリー",
          "value": "",
          "class": "tableHeader white--text",
        },
        {
          "text": "ブランド",
          "value": "detail",
          "class": "tableHeader white--text",
        },
        {
          "text": "定価（税抜）",
          "value": "detail",
          "class": "tableHeader white--text",
        },
        {
          "text": "仕入先",
          "value": "",
          "class": "tableHeader white--text",
          "width": "300px"
        },
        {
          "text": "原価（税抜）",
          "value": "",
          "class": "tableHeader white--text",
          "width": "200"
        },
        {
          "text": "コピー",
          "value": "",
          "class": "tableHeader white--text"
        },
        {
          "text": "削除",
          "value": "",
          "class": "tableHeader white--text"
        }
      ]
    }
  },
  methods: {
    async copyDetail(item){
      await this.$router.push({
        name: 'CopyGood',
        params: {
          item: item
        }
      })
    },
    async editItem(id) {
      await this.$router.push({
        name: 'update-good',
        params: {
          id: id
        }
      })
    },
    async deleteItem(id) {
      const confirmDelete = window.confirm("本当に削除しますか？");
      if (confirmDelete) {
        try {
          await deleteGood({id: id.toString()});
          alert("削除成功しました。")
          location.reload();
        } catch (e) {
          alert("削除失敗しました。")
        }
      }

    }
  }
}
</script>

<style scoped>

</style>
