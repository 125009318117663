import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from "@/views/login.vue";
import TopPage from "@/views/TopPage.vue";
import AddGood from "@/views/AddGood.vue";
import ImportGoodCSV from "@/views/ImportGoodCSV.vue";
import GoodView from "@/views/GoodView.vue";
import UpdateGood from "@/views/UpdateGood.vue";
import AddCompany from "@/views/AddCompany.vue";
import CompanyView from "@/views/CompanyView.vue";
import UpdateCompany from "@/views/UpdateCompany.vue";
import AddCategory from "@/views/AddCategory.vue";
import categoryView from "@/views/CategoryView.vue";
import updateCategory from "@/views/UpdateCategory.vue";
import pdfHCYU from "@/views/pdfHCYU.vue";
import pdfRequest from "@/views/pdfRequest.vue";
import RequestView from "@/views/RequestView.vue";
import PdfRequestEdit from "@/views/pdfRequestEdit.vue";
import HcyuView from "@/views/HcyuView.vue";
import HcyuRequestEdit from "@/views/HcyuRequestEdit.vue";
import StockView from "@/views/StockView.vue";
import EditStock from "@/views/EditStock.vue";
import EditStockRequest from "@/views/EditStockRequest.vue";
import AdminStockEdit from "@/views/AdminStockEdit.vue";
import StockDetailShow from "@/views/StockDetailShow.vue";
import pdfHCYUAdd from "@/views/pdfHCYUAdd.vue";
import pdfRequestAdd from "@/views/pdfRequestAdd.vue";
import MoneyView from "@/views/MoneyView.vue";
import EditMoney from "@/views/EditMoney.vue";
import EditMoneyRequest from "@/views/EditMoneyRequest.vue";
import MoneyViewHcyu from "@/views/MoneyViewHcyu.vue";
import CopyGood from "@/views/CopyGood.vue";
import pdfRequestFromHcyu from "@/views/pdfRequestFromHcyu.vue";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'login-page',
        component: Login
    },
    {
        path: '/top',
        name: 'top-page',
        component: TopPage
    },
    {
        path: '/addProduct',
        name: 'add-good',
        component: AddGood
    },
    {
        path: '/importGoodCSV',
        name: 'import-good-CSV',
        component: ImportGoodCSV
    },
    {
        path: '/goodView',
        name: 'good-view',
        component: GoodView
    },
    {
        path: '/updateGood',
        name: 'update-good',
        component: UpdateGood
    }
    ,
    {
        path: '/addCompany',
        name: 'add-company',
        component: AddCompany
    }
    ,
    {
        path: '/companyView',
        name: 'company-view',
        component: CompanyView
    }
    ,
    {
        path: '/updateCompany',
        name: 'update-company',
        component: UpdateCompany
    },
    {
        path: '/addCategory',
        name: 'add-category',
        component: AddCategory
    },
    {
        path: '/categoryView',
        name: 'category-view',
        component: categoryView
    },
    {
        path: '/updateCategory',
        name: 'update-category',
        component: updateCategory
    }
    ,
    {
        path: '/pdfHCYU',
        name: 'pdfHCYU',
        component: pdfHCYU
    },
    {
        path: '/pdfRequest',
        name: 'pdfRequest',
        component: pdfRequest
    },
    {
        path: '/requestView',
        name: 'RequestView',
        component: RequestView
    },
    {
        path: '/pdfRequestEdit',
        name: 'PdfRequestEdit',
        component: PdfRequestEdit
    },
    {
        path: '/hcyuView',
        name: 'HcyuView',
        component: HcyuView
    },
    {
        path: '/hcyuRequestEdit',
        name: 'HcyuRequestEdit',
        component: HcyuRequestEdit
    },
    {
        path: '/stockView',
        name: 'StockView',
        component: StockView
    },
    {
        path: '/editStock',
        name: 'EditStock',
        component: EditStock
    },
    {
        path: '/editStockRequest',
        name: 'EditStockRequest',
        component: EditStockRequest
    },
    {
        path: '/adminStockEdit',
        name: 'AdminStockEdit',
        component: AdminStockEdit
    },
    {
        path: '/stockDetailShow',
        name: 'StockDetailShow',
        component: StockDetailShow
    },
    {
        path: '/pdfHCYUAdd',
        name: 'pdfHCYUAdd',
        component: pdfHCYUAdd
    },
    {
        path: '/pdfRequestAdd',
        name: 'pdfRequestAdd',
        component: pdfRequestAdd
    },
    {
        path: '/moneyView',
        name: 'MoneyView',
        component: MoneyView
    },
    {
        path: '/editMoney',
        name: 'EditMoney',
        component: EditMoney
    },
    {
        path: '/editMoneyRequest',
        name: 'EditMoneyRequest',
        component: EditMoneyRequest
    },
    {
        path: '/moneyViewHcyu',
        name: 'MoneyViewHcyu',
        component: MoneyViewHcyu
    }
    ,
    {
        path: '/CopyGood',
        name: 'CopyGood',
        component: CopyGood
    }
    , {
        path: '/pdfRequestFromHcyu',
        name: 'pdfRequestFromHcyu',
        component: pdfRequestFromHcyu
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
