var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-data-table',{staticStyle:{"width":"100%"},attrs:{"dense":"","headers":_vm.headers,"loading":this.isLoading,"items":_vm.items,"items-per-page":20,"item-key":"id","footer-props":{
    'items-per-page-options': [20, 50, 100],
    showFirstLastPage: true,
    firstIcon: 'mdi-arrow-collapse-left',
    lastIcon: 'mdi-arrow-collapse-right',
    prevIcon: 'mdi-arrow-left',
    nextIcon: 'mdi-arrow-right'
  }},scopedSlots:_vm._u([{key:"body",fn:function({ items }){return [_c('tbody',[(_vm.isLoading)?_c('tr',[_c('td')]):_vm._e(),_vm._l((items),function(item){return _c('tr',{key:item.id},[_c('td',[_vm._v(_vm._s(item.requestDate))]),_c('td',[_vm._v(_vm._s(item.requestNum))]),_c('td',[_vm._v(_vm._s(item.createUser))]),_c('td',[_vm._v(_vm._s(item.companyName))]),_c('td',[_vm._v(_vm._s(item.totalPrice))]),_c('td',[_vm._v(_vm._s(_vm.noPayMoney(JSON.parse(item.payInfo), item.totalPrice)))]),_c('td',[_vm._v(_vm._s(item.note))]),_c('td',{style:({ color: _vm.getStockStatusColor(item.payStatus) })},[_c('div',{staticStyle:{"cursor":"pointer"}},[_vm._v(_vm._s(_vm.showPayStatusStringH(item.payStatus)))])])])})],2)]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }