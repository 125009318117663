<template>
  <v-dialog
      v-model="isShow"
      width="400px"
      @click:outside="closeDialog"
      @keydown.esc="closeDialog"
  >
    <v-card>
      <v-card-title>{{ dialogInfo.title }}</v-card-title>
      <v-card-text class="black--text">
        <div>
          <div
              v-for="(item,index) in dialogInfo.contents.split('\n')"
              :key="index"
              style="font-size: small"
          >
            <p v-for="(item1, index1) in item.split('\tn')"
               :key="index1" class="ma-0 pa-0"
            >
              {{ item1 }}
            </p>
          </div>
          <span class="supplement-contents">
            {{ dialogInfo.supplementContents }}
          </span>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-col cols="12">
          <div class="d-flex justify-end">
            <v-btn
             style="color: #DA0348;background-color: white;box-shadow: none;
            outline: grey solid thin;font-weight: bold;"
             @click="closeDialog"
            >
              {{ dialogInfo.positiveButtonLabel }}
            </v-btn>
          </div>
        </v-col>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "ErrorDialog",
  computed: {
    ...mapGetters({
      isShow: "errorDialog/isShow",
      dialogInfo: "errorDialog/dialogInfo"
    })
  },
  methods:{
    closeDialog(){
      this.$store.dispatch("errorDialog/refresh");
    }
  }
}
</script>

<style scoped>

.supplement-contents {
  white-space: pre-line;
}

</style>