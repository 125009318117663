<template>
  <with-side-menu>
    <v-container fluid>
      <v-row>
        <v-col cols="12">
          <v-row class="ml-3 mb-3">
            <h1>カテゴリ更新</h1>
          </v-row>
          <v-row dense>
            <v-col cols="2"></v-col>
            <v-col cols="2">
              <p class="web-title">カテゴリ<span class="red-asterisk">*</span></p>
            </v-col>
            <v-col cols="4">
              <v-text-field dense outlined v-model="categoryName" :rules="rules">
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="2"></v-col>
            <v-col cols="2">
              <p class="web-title">ブランド<span class="red-asterisk">*</span></p>
            </v-col>
            <v-col cols="4">
              <v-text-field dense outlined v-model="brandName" :rules="rules">
              </v-text-field>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col cols="12" class="text-center">
              <v-btn width="120px" class=" white--text button" color="mainColor" @click="addCategory()">更新</v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </with-side-menu>
</template>

<script>
import WithSideMenu from "@/components/WithSideMenu";
import {searchCategory, updateCategory} from "@/api/category";
import store from "@/store";
import {isEmpty} from "@/utils/common";

export default {
  name: "AddCompany",
  components: {
    WithSideMenu
  },
  data() {
    return {
      categoryName: "",
      brandName: "",
      loading: false,
      rules: [
        value => {
          if (value) return true
          return '必要項目、入力してください。'
        }
      ]
    }
  },
  async mounted() {
    const response = await searchCategory({id: this.$route.params.id.toString()})
    const result = response.data.result[0];
    this.categoryName = result.categoryName;
    this.brandName = result.brandName;
  },
  methods: {
    async addCategory() {
      this.loading = true;
      if (isEmpty(this.categoryName) || isEmpty(this.brandName)) {
        alert("必要な情報を入力してください。");
        return;
      }

      try {
        await updateCategory({
          id: this.$route.params.id.toString(),
          categoryName: this.categoryName,
          brandName: this.brandName,
          updateName: store.state.userInfo.userName
        })
        alert("カテゴリ更新成功しました。");
        await this.$router.push({
          name: 'category-view',
        })
      } catch (e) {
        alert("カテゴリ更新失敗しました。");
      }
      this.loading = false;
    }
  }
}
</script>

<style scoped>
.web-title {
  font-size: 18px;
}

.red-asterisk {
  color: red;
}
</style>
