var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-data-table',{staticStyle:{"width":"100%"},attrs:{"dense":"","headers":_vm.headers,"loading":this.isLoading,"items":_vm.items,"items-per-page":20,"item-key":"id","footer-props":{
    'items-per-page-options': [20, 50, 100],
    showFirstLastPage: true,
    firstIcon: 'mdi-arrow-collapse-left',
    lastIcon: 'mdi-arrow-collapse-right',
    prevIcon: 'mdi-arrow-left',
    nextIcon: 'mdi-arrow-right'
  }},scopedSlots:_vm._u([{key:"body",fn:function({ items }){return [_c('tbody',[(_vm.isLoading)?_c('tr',[_c('td')]):_vm._e(),_vm._l((items),function(item){return _c('tr',{key:item.id},[_c('td',[_vm._v(_vm._s(item.requestDate))]),_c('td',[_vm._v(_vm._s(item.requestNum))]),_c('td',[_vm._v(_vm._s(item.companyName))]),_c('td',[_vm._v(_vm._s(_vm.fixPrice(item.totalPrice)))]),_c('td',[_vm._v(_vm._s(item.createUser))]),_c('td',[_vm._v(_vm._s(item.note))]),_c('td',[_vm._v(_vm._s(item.receviceDate))]),_c('td',[_c('v-icon',{on:{"click":function($event){return _vm.preRev(item)}}},[_vm._v(" mdi-file-find ")]),_c('v-icon',{staticClass:"pl-2",on:{"click":function($event){return _vm.edit(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{staticClass:"pl-2",on:{"click":function($event){return _vm.downloadPDF(item)}}},[_vm._v(" mdi-arrow-down-bold ")]),_c('v-icon',{staticClass:"pl-2",on:{"click":function($event){return _vm.copy(item)}}},[_vm._v(" mdi-content-copy ")]),_c('v-icon',{staticClass:"pl-2",on:{"click":function($event){return _vm.copyRequest(item)}}},[_vm._v(" mdi-invoice-edit ")])],1),_c('td',{style:({ color: _vm.getStockStatusColor(item.stockStatus) })},[_c('div',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.editStock(item)}}},[_vm._v(_vm._s(_vm.showStockStatusString(item.stockStatus)))])]),_c('td',{style:({ color: _vm.getStockStatusColor(item.payStatus) })},[_c('div',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.editPrice(item)}}},[_vm._v(_vm._s(_vm.showPayStatusStringH(item.payStatus)))])]),_c('td',[_c('v-icon',{staticClass:"pl-2",on:{"click":function($event){return _vm.deleteItem(item.requestNum)}}},[_vm._v(" mdi-delete ")])],1)])})],2)]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }