<template>
  <with-header>
    <v-main style="padding-top: 48px;">
      <SideMenu />
      <slot></slot>
    </v-main>
  </with-header>
</template>
<script>
import WithHeader from "./WithHeader.vue";
import SideMenu from "@/components/SideMenu";
export default {
  name: "WithSideMenu",
  components: { WithHeader,SideMenu}
};
</script>
