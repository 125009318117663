<template>
  <with-side-menu>
    <v-container fluid>
      <v-row justify="start" align="center" dense>
        <v-col cols="2">
          <h1>会社一覧</h1>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="2">
          <v-select
              label="種類"
              outlined
              dense
              v-model="selected"
              :items="items"
              item-text="text"
              item-value="value"
          ></v-select>
        </v-col>
        <v-col cols="2">
          <v-text-field dense
                        label="法人名"
                        v-model="corporateName"
                        hide-details
                        outlined
                        @keyup.enter="searchCompany"
          ></v-text-field>
        </v-col>
        <v-col cols="2">
          <v-text-field dense
                        label="請求書宛先会社名"
                        v-model="companyName"
                        hide-details
                        outlined
                        @keyup.enter="searchCompany"
          ></v-text-field>
        </v-col>
        <v-col cols="2">
          <v-btn
              color="mainColor"
              class="white--text button"
              style="width: 150px"
              :loading="searchFlag"
              :disabled="searchFlag"
              @click="searchCompany()"
          >検索
          </v-btn>
        </v-col>
        <v-col cols="3">
          <v-btn
              color="orange"
              class="white--text button"
              :loading="searchFlag"
              :disabled="searchFlag"
              @click="downloadCSV"
          >検索結果CSVファイルダウンロード
          </v-btn>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="2">
          <v-btn
              color="red"
              class="white--text button"
              style="width: 150px"
              @click="clear()"
          >クリア
          </v-btn>
        </v-col>
      </v-row>
      <v-row align="center" justify="center" style="margin-top: 70px">
        <CompanyTable :items="searchDate"></CompanyTable>
      </v-row>
    </v-container>
  </with-side-menu>
</template>

<script>
import WithSideMenu from "@/components/WithSideMenu";
import {getCompanyList} from "@/api/company";
import CompanyTable from "@/components/table/CompanyTable.vue";
import {isEmpty} from "@/utils/common";
import Papa from "papaparse";


export default {
  name: "CompanyView",
  components: {
    WithSideMenu,
    CompanyTable
  },
  data() {
    return {
      corporateName: "",
      companyName: "",
      searchFlag: false,
      searchDate: [],
      selected: null,
      items: [
        {text: '全て', value: null},
        {text: '顧客', value: '0'},
        {text: '仕入先', value: '1'}
      ],
    }
  },
  async mounted() {
    try {
      let result = await getCompanyList({});
      this.searchDate = result.data.result;
    } catch (e) {
      alert("初期データ取得失敗しました。")
    }
  },
  methods: {
    clear() {
      this.corporateName = "";
      this.companyName = "";
      this.selected = null;
    },
    async downloadCSV() {
      console.log(this.searchDate)
      this.searchFlag = true;
      let result = await getCompanyList({
        typeId: isEmpty(this.selected) ? null : this.selected.toString(),
        corporateName: isEmpty(this.corporateName) ? null : this.corporateName,
        companyName: isEmpty(this.companyName) ? null : this.companyName
      });
      this.searchDate = result.data.result;
      const headers = [{text: '法人名', value: 'corporateName'},
        {text: '請求書宛先会社名', value: 'companyName'},
        {text: '電話', value: 'phone'},
        {text: 'メールアドレス', value: 'mail'},
        {text: 'アドレス', value: 'address'},
        {text: '支払方法', value: 'payMethod'},
        {text: '種類', value: 'typeId'}];

      const csv = Papa.unparse({
        fields: headers.map(header => header.text),
        data: this.searchDate.map(item => {
          const rowData = [];
          headers.forEach(header => {
            if (header.value === 'typeId') {
              if (item['typeId'].toString() === "0") {
                rowData.push('顧客');
              } else {
                rowData.push('仕入先');
              }
            } else {
              rowData.push(item[header.value]);
            }
          });
          return rowData;
        }),
      });
      // 创建一个Blob对象并生成下载链接
      const blob = new Blob([new Uint8Array([0xEF, 0xBB, 0xBF]), csv], {type: 'text/csv;charset=utf-8;'});
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = 'company_info.csv';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      this.searchFlag = false;
    },
    async searchCompany() {
      this.searchFlag = true;
      try {
        let result = await getCompanyList({
          typeId: isEmpty(this.selected) ? null : this.selected.toString(),
          corporateName: isEmpty(this.corporateName) ? null : this.corporateName,
          companyName: isEmpty(this.companyName) ? null : this.companyName
        });
        this.searchDate = result.data.result;
      } catch (e) {
        alert("データ取得失敗しました。")
      }
      this.searchFlag = false;
    }
  }
}
</script>

<style scoped>

</style>
