<template>
  <with-side-menu>
    <v-container fluid>
      <v-row justify="start" align="center" dense>
        <v-col cols="2">
          <h1>出金一覧</h1>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="2">
          <v-text-field dense
                        label="発注日(検索開始日)"
                        v-model="startDate"
                        hide-details
                        outlined
                        @keyup.enter="searchStocks"
          ></v-text-field>
        </v-col>
        <v-col cols="1">
          <v-menu
              offset-y
              v-model="menu"
              :close-on-content-click="false"
              transition="scale-transition"
          >
            <template v-slot:activator="{ on, attrs}">
              <v-icon large color="mainColor" v-bind="attrs" v-on="on">
                mdi-calendar-month-outline
              </v-icon>
            </template>
            <v-date-picker
                v-model="startDate"
                no-title
                @input="menu = false"
                locale="ja_jp"
            >
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="2">
          <v-text-field dense
                        label="発注日(検索終了日)"
                        v-model="endDate"
                        hide-details
                        outlined
                        @keyup.enter="searchStocks"
          ></v-text-field>
        </v-col>
        <v-col cols="1">
          <v-menu
              offset-y
              v-model="menu1"
              :close-on-content-click="false"
              transition="scale-transition"
          >
            <template v-slot:activator="{ on, attrs}">
              <v-icon large color="mainColor" v-bind="attrs" v-on="on">
                mdi-calendar-month-outline
              </v-icon>
            </template>
            <v-date-picker
                v-model="endDate"
                no-title
                @input="menu1 = false"
                locale="ja_jp"
            >
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="2">
          <v-select dense
                    label="状態"
                    v-model="status"
                    :items="items"
                    item-value="value"
                    item-text="label"
                    outlined
          ></v-select>
        </v-col>
        <v-col cols="4"></v-col>
        <v-col cols="2">
          <v-text-field dense
                        label="発注番号"
                        v-model="requestNum"
                        hide-details
                        outlined
                        @keyup.enter="searchStocks"
          ></v-text-field>
        </v-col>
        <v-col cols="2">
          <v-text-field dense
                        label="会社名"
                        v-model="companyName"
                        hide-details
                        outlined
                        @keyup.enter="searchStocks"
          ></v-text-field>
        </v-col>
        <v-col cols="2">
          <v-text-field dense
                        label="発注金额"
                        v-model="requestMoney"
                        hide-details
                        outlined
                        @keyup.enter="searchStocks"
          ></v-text-field>
        </v-col>
        <v-col cols="3">
          <v-btn
              color="mainColor"
              class="white--text button"
              style="width: 150px"
              :loading="searchFlag"
              :disabled="searchFlag"
              @click="searchStocks()"
          >検索
          </v-btn>
        </v-col>
        <v-col cols="2">
          <v-btn
              color="red"
              class="white--text button"
              style="width: 150px"
              @click="clear()"
          >クリア
          </v-btn>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="3">
          合計発注金額: {{ fixPrice(tableAllMoney) }}
          <br>
          <br>
          支払完了金額: {{ fixPrice(tableAllMoney-noAllPay) }}
          <br>
          <br>
          未払金額: {{ fixPrice(noAllPay) }}
        </v-col>
      </v-row>
      <v-row align="center" justify="center" style="margin-top: 70px">
        <MoneyHcyuTable :items="searchDate"></MoneyHcyuTable>
      </v-row>
    </v-container>
  </with-side-menu>
</template>

<script>
import WithSideMenu from "@/components/WithSideMenu";
import {getMoneyListRequest} from "@/api/money";
import {isEmpty, fixPrice} from "@/utils/common";
import MoneyHcyuTable from "@/components/table/MoneyHcyuTable.vue";


export default {
  name: "MoneyViewHcyu",
  components: {
    WithSideMenu,
    MoneyHcyuTable
  },
  data() {
    return {
      menu: false,
      menu1: false,
      startDate: null,
      endDate: null,
      requestNum: null,
      companyName: null,
      requestMoney: null,
      status: null,
      tableAllMoney: 0,
      noAllPay: 0,
      items: [
        {label: 'すべて', value: null},
        {label: '出金待ち', value: 0},
        {label: '一部出金', value: 1},
        {label: '出金完了', value: 2}
      ],
      searchFlag: false,
      searchDate: []
    }
  },
  async mounted() {
    try {
      let result = await getMoneyListRequest({type: 0});
      this.searchDate = result.data.result;
      this.tableAllMoney = 0;
      this.noAllPay = 0;
      for (let i = 0; i < this.searchDate.length; i++) {
        this.tableAllMoney = this.tableAllMoney + Number(this.searchDate[i].totalPrice);
        let tempMoney = 0;
        if (this.searchDate[i].payInfo != null) {
          tempMoney = JSON.parse(this.searchDate[i].payInfo).reduce((total, currentItem) => {
            return total + Number(currentItem.money);
          }, 0);
        }
        this.noAllPay = this.noAllPay + Number(this.searchDate[i].totalPrice) - tempMoney;
      }
    } catch (e) {
      alert("初期データ取得失敗しました。")
    }
  },
  methods: {
    clear() {
      this.startDate = null;
      this.endDate = null;
      this.requestNum = null;
      this.companyName = null;
      this.requestMoney = null;
      this.status = null;
    },
    fixPrice,
    async searchStocks() {
      this.searchFlag = true;
      try {
        let result = await getMoneyListRequest({
          startDate: isEmpty(this.startDate) ? null : this.startDate,
          endDate: isEmpty(this.endDate) ? null : this.endDate,
          requestNum: isEmpty(this.requestNum) ? null : this.requestNum,
          companyName: isEmpty(this.companyName) ? null : this.companyName,
          totalPrice: isEmpty(this.requestMoney) ? null : this.requestMoney,
          status: this.status == null ? null : Number(this.status),
          type: 0
        });
        this.searchDate = result.data.result;
      } catch (e) {
        alert("データ取得失敗しました。")
      }
      this.tableAllMoney = 0;
      this.noAllPay = 0;
      for (let i = 0; i < this.searchDate.length; i++) {
        this.tableAllMoney = this.tableAllMoney + Number(this.searchDate[i].totalPrice);
        let tempMoney = 0;
        if (this.searchDate[i].payInfo != null) {
          tempMoney = JSON.parse(this.searchDate[i].payInfo).reduce((total, currentItem) => {
            return total + Number(currentItem.money);
          }, 0);
        }
        this.noAllPay = this.noAllPay + Number(this.searchDate[i].totalPrice) - tempMoney;
      }
      this.searchFlag = false;

    }
  }
}
</script>

<style scoped>

</style>
