var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-data-table',{staticStyle:{"width":"100%"},attrs:{"dense":"","headers":_vm.headers,"loading":this.isLoading,"items":_vm.items,"items-per-page":20,"item-key":"id","footer-props":{
    'items-per-page-options': [20, 50, 100],
    showFirstLastPage: true,
    firstIcon: 'mdi-arrow-collapse-left',
    lastIcon: 'mdi-arrow-collapse-right',
    prevIcon: 'mdi-arrow-left',
    nextIcon: 'mdi-arrow-right'
  }},scopedSlots:_vm._u([{key:"body",fn:function({ items }){return [_c('tbody',[(_vm.isLoading)?_c('tr',[_c('td')]):_vm._e(),_vm._l((items),function(item){return _c('tr',{key:item.id},[_c('td',[_vm._v(_vm._s(item.jancode))]),_c('td',[_vm._v(_vm._s(item.nameJp))]),_c('td',[_vm._v(_vm._s(item.categoryName))]),_c('td',[_vm._v(_vm._s(item.brandName))]),_c('td',[_vm._v(_vm._s(item.priceExcludingTax))]),_c('td',[_vm._v(_vm._s(item.stockNumber))]),_c('td',[_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.update(item.jancode,item.nameJp,item.priceExcludingTax)}}},[_vm._v(" mdi-fountain-pen-tip ")])],1),_c('td',[_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.detaiShow(item.jancode,item.nameJp)}}},[_vm._v(" mdi-eye ")])],1)])})],2)]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }