var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-data-table',{staticStyle:{"width":"100%"},attrs:{"dense":"","headers":_vm.headers,"loading":this.isLoading,"items":_vm.items,"items-per-page":20,"item-key":"id","footer-props":{
    'items-per-page-options': [20, 50, 100],
    showFirstLastPage: true,
    firstIcon: 'mdi-arrow-collapse-left',
    lastIcon: 'mdi-arrow-collapse-right',
    prevIcon: 'mdi-arrow-left',
    nextIcon: 'mdi-arrow-right'
  }},scopedSlots:_vm._u([{key:"body",fn:function({ items }){return [_c('tbody',[(_vm.isLoading)?_c('tr',[_c('td')]):_vm._e(),_vm._l((items),function(item){return _c('tr',{key:item.id,on:{"dblclick":function($event){return _vm.editItem(item.id)}}},[_c('td',[_vm._v(_vm._s(item.typeId === '1' ? "仕入先" : "顧客"))]),_c('td',[_vm._v(_vm._s(item.corporateName))]),_c('td',[_vm._v(_vm._s(item.companyName))]),_c('td',[_vm._v(_vm._s(item.payMethod))]),_c('td',[_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item.id)}}},[_vm._v(" mdi-delete ")])],1)])})],2)]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }