<template>
  <v-app-bar app absolute color="mainColor" class="bar_style" dense>
    <v-toolbar-title class="white--text"
    >五洲在庫管理
    </v-toolbar-title
    >
    <v-spacer></v-spacer>
    <div style="margin-right: 20px;color: white">
      日本現在時:{{ japanTime }}
    </div>
    <div style="margin-right: 20px;color: white">
      {{ userName }}さん ようこそ
    </div>
    <div>
      <div>
        <v-btn
            depressed
            small
            color="white"
            class="mainColor--text"
            @click="logout"
        >
          ログアウト
        </v-btn>
      </div>
    </div>
  </v-app-bar>
</template>
<script>
import {mapGetters} from "vuex";

export default {
  name: "HeaderPage",
  data() {
    return {
      japanTime: ''
    };
  },
  mounted() {
    this.updateJapanTime();
    setInterval(this.updateJapanTime, 1000); // 更新时间每秒钟
  },
  computed: {
    ...mapGetters('userInfo', ['userName'])
  },
  methods: {
    updateJapanTime() {
      this.japanTime = new Date().toLocaleString('ja-JP', {timeZone: 'Asia/Tokyo'});
    },
    logout() {
      localStorage.clear();
      this.$router.push({
        path: "/"
      });
    }
  }
};
</script>
<style lang="scss" scoped></style>
