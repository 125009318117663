<template>
  <with-side-menu>
    <v-container fluid>
      <v-row>
        <v-col cols="12">
          <div>
            次リリース内容<br>
            在庫管理、出入庫、出入金、権限管理、人員管理など
            <br>
            何か不明点あれば、 <p style="color: red"><b>Wechat:tzw951002</b></p> に連絡してください。
          </div>
        </v-col>
      </v-row>
    </v-container>
  </with-side-menu>
</template>

<script>
import WithSideMenu from "@/components/WithSideMenu";

export default {
  name: "TopPage",
  components: {
    WithSideMenu
  },
}
</script>

<style scoped>
</style>