<template>
  <v-data-table
      style="width: 100%"
      dense
      :headers="headers"
      :loading="this.isLoading"
      :items="items"
      :items-per-page="20"
      item-key="id"
      :footer-props="{
      'items-per-page-options': [20, 50, 100],
      showFirstLastPage: true,
      firstIcon: 'mdi-arrow-collapse-left',
      lastIcon: 'mdi-arrow-collapse-right',
      prevIcon: 'mdi-arrow-left',
      nextIcon: 'mdi-arrow-right'
    }"
  >
    <template v-slot:body="{ items }">
      <tbody>
      <tr v-if="isLoading">
        <td></td>
      </tr>
      <tr
          v-for="item in items"
          :key="item.id"
          @dblclick="editItem(item.id)"
      >
        <td>{{ item.categoryName }}</td>
        <td>{{ item.brandName }}</td>
        <td>
          <v-icon
              small
              @click="deleteItem(item.id)"
          >
            mdi-delete
          </v-icon>
        </td>
      </tr>
      </tbody>
    </template>
  </v-data-table>
</template>

<script>
import {deleteCategory} from "@/api/category";

export default {
  name: "CompanyTable",
  props: {
    items: Array,
    isLoading: Boolean
  },
  data() {
    return {
      headers: [
        {
          "text": "カテゴリ",
          "value": "corporateName",
          "class": "tableHeader white--text",
        },
        {
          "text": "ブランド",
          "value": "companyName",
          "align": "start",
          "class": "tableHeader white--text"
        },
        {
          "text": "削除",
          "value": "",
          "class": "tableHeader white--text"
        }
      ]
    }
  },
  methods: {
    async editItem(id) {
      await this.$router.push({
        name: 'update-category',
        params: {
          id: id
        }
      })
    },
    async deleteItem(id) {
      const confirmDelete = window.confirm("本当に削除しますか？");
      if (confirmDelete) {
        try {
          await deleteCategory({id: id.toString()});
          alert("削除成功しました。")
          location.reload();
        } catch (e) {
          alert("削除失敗しました。")
        }
      }

    }
  }
}
</script>

<style scoped>

</style>
