<template>
  <v-data-table
      style="width: 100%"
      dense
      :headers="headers"
      :loading="this.isLoading"
      :items="items"
      :items-per-page="20"
      item-key="id"
      :footer-props="{
      'items-per-page-options': [20, 50, 100],
      showFirstLastPage: true,
      firstIcon: 'mdi-arrow-collapse-left',
      lastIcon: 'mdi-arrow-collapse-right',
      prevIcon: 'mdi-arrow-left',
      nextIcon: 'mdi-arrow-right'
    }"
  >
    <template v-slot:body="{ items }">
      <tbody>
      <tr v-if="isLoading">
        <td></td>
      </tr>
      <tr
          v-for="item in items"
          :key="item.id"
      >
        <td>{{ item.requestDate }}</td>
        <td>{{ item.requestNum }}</td>
        <td>{{ item.createUser }}</td>
        <td>{{ item.companyName }}</td>
        <td>{{ item.totalPrice }}</td>
        <td>{{ noPayMoney(JSON.parse(item.payInfo), item.totalPrice) }}</td>
        <td>{{ item.note }}</td>
        <td :style="{ color: getStockStatusColor(item.payStatus) }">
          <div style="cursor: pointer">{{ showPayStatusStringH(item.payStatus) }}</div>
        </td>
      </tr>
      </tbody>
    </template>
  </v-data-table>
</template>

<script>
import {showPayStatusStringH, getStockStatusColor} from "@/utils/common";

export default {
  name: "MoneyHcyuTable",
  props: {
    items: Array,
    isLoading: Boolean
  },
  data() {
    return {
      headers: [
        {
          "text": "発注日",
          "value": "requestDate",
          "class": "tableHeader white--text",
        },
        {
          "text": "発注番号",
          "value": "requestNum",
          "align": "start",
          "class": "tableHeader white--text"
        },
        {
          "text": "担当",
          "value": "createUser",
          "class": "tableHeader white--text",
        },
        {
          "text": "会社名",
          "value": "companyName",
          "class": "tableHeader white--text",
        },
        {
          "text": "発注金额",
          "value": "totalPrice",
          "class": "tableHeader white--text",
        },
        {
          "text": "残金",
          "value": "",
          "class": "tableHeader white--text",
        },
        {
          "text": "メモ",
          "value": "note",
          "class": "tableHeader white--text",
        },
        {
          "text": "状態",
          "value": "payStatus",
          "class": "tableHeader white--text",
        }
      ]
    }
  },
  methods: {
    getStockStatusColor, showPayStatusStringH,
    noPayMoney(payInfo, totalMoney) {
      if (payInfo != null) {
        let tempMoney = payInfo.reduce((total, currentItem) => {
          return total + Number(currentItem.money);
        }, 0);
        return Number(totalMoney) - tempMoney
      }else{
        return totalMoney;
      }
    }
  }
}
</script>

<style scoped>

</style>
