import {postWithToken} from "@/api/RestClient";
import {showDialog} from "@/utils/dialog";

export const insertCompany = async payload => {
    try {
        return postWithToken("author/company/insert", payload);
    } catch (error) {
        await showDialog("", error.response.data.message, "")
        throw error;
    }
};

export const getCompanyList = async payload => {
    try {
        return postWithToken("author/company/companyList", payload);
    } catch (error) {
        await showDialog("", error.response.data.message, "")
        throw error;
    }
};

export const deleteCompany = async payload => {
    try {
        return postWithToken("author/company/delete", payload);
    } catch (error) {
        await showDialog("", error.response.data.message, "")
        throw error;
    }
}

export const getCompanyDetail = async payload => {
    try {
        return postWithToken("author/company/companyDetail", payload);
    } catch (error) {
        await showDialog("", error.response.data.message, "")
        throw error;
    }
};

export const updateCompany = async payload => {
    try {
        return postWithToken("author/company/updateCompany", payload);
    } catch (error) {
        await showDialog("", error.response.data.message, "")
        throw error;
    }
};