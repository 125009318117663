<template>
  <with-side-menu>
    <v-container fluid>
      <v-row>
        <v-col cols="12">
          <v-row class="ml-3 mb-3">
            <h1>会社更新</h1>
          </v-row>
          <v-row dense>
            <v-col cols="2"></v-col>
            <v-col cols="2">
              <p class="web-title">種類<span class="red-asterisk">*</span></p>
            </v-col>
            <v-col cols="4">
              <v-select
                  outlined
                  dense
                  v-model="selected"
                  :items="items"
                  item-text="text"
                  item-value="value"
              ></v-select>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="2"></v-col>
            <v-col cols="2">
              <p class="web-title">法人名<span class="red-asterisk">*</span></p>
            </v-col>
            <v-col cols="4">
              <v-text-field dense outlined v-model="corporateName" :rules="rules">
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="2"></v-col>
            <v-col cols="2">
              <p class="web-title">請求書宛先会社名<span class="red-asterisk">*</span></p>
            </v-col>
            <v-col cols="4">
              <v-text-field dense outlined v-model="companyName" :rules="rules">
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="2"></v-col>
            <v-col cols="2">
              <p class="web-title">決済方法<span class="red-asterisk">*</span></p>
            </v-col>
            <v-col cols="4">
              <v-select
                  outlined
                  dense
                  v-model="payMethod"
                  :items="payMethodItems"
              ></v-select>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="2"></v-col>
            <v-col cols="2">
              <p class="web-title">郵便</p>
            </v-col>
            <v-col cols="4">
              <v-text-field dense outlined v-model="zip">
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="2"></v-col>
            <v-col cols="2">
              <p class="web-title">住所</p>
            </v-col>
            <v-col cols="4">
              <v-text-field dense outlined v-model="address">
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="2"></v-col>
            <v-col cols="2">
              <p class="web-title">電話</p>
            </v-col>
            <v-col cols="4">
              <v-text-field dense outlined v-model="phone">
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="2"></v-col>
            <v-col cols="2">
              <p class="web-title">EMAIL</p>
            </v-col>
            <v-col cols="4">
              <v-text-field dense outlined v-model="mail">
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="2"></v-col>
            <v-col cols="2">
              <p class="web-title">担当</p>
            </v-col>
            <v-col cols="4">
              <v-text-field dense outlined v-model="name">
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="2"></v-col>
            <v-col cols="2">
              <p class="web-title">備考（任意）</p>
            </v-col>
            <v-col cols="4">
              <v-text-field dense outlined v-model="info">
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="2"></v-col>
            <v-col cols="2">
              <p class="web-title">国</p>
            </v-col>
            <v-col cols="4">
              <v-text-field dense outlined v-model="country">
              </v-text-field>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col cols="6" class="text-center">
              <v-btn width="120px" class=" white--text button" color="mainColor" @click="updateCompany()">更新</v-btn>
            </v-col>
            <v-col cols="6" class="text-center">
              <v-btn width="120px" class=" white--text button" color="mainColor" @click="backToList()">一覧に戻る
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </with-side-menu>
</template>

<script>
import WithSideMenu from "@/components/WithSideMenu";
import {getCompanyDetail, updateCompany} from "@/api/company";
import store from "@/store";
import {isEmpty} from "@/utils/common";

export default {
  name: "UpdateCompany",
  components: {
    WithSideMenu
  },
  data() {
    return {
      selected: '0',
      items: [
        {text: '顧客', value: '0'},
        {text: '仕入先', value: '1'},
      ],
      payMethodItems: [
        "前払", "後払"
      ],
      payMethod: "前払",
      corporateName: "",
      companyName: "",
      phone: "",
      mail: "",
      name: "",
      zip: "",
      address: "",
      info: "",
      country: "",
      loading: false,
      rules: [
        value => {
          if (value) return true
          return '必要項目、入力してください。'
        }
      ]
    }
  },
  async mounted() {
    try {
      const response = await getCompanyDetail({id: this.$route.params.id.toString()})
      const resultBody = response.data.result;
      console.log(resultBody)
      this.corporateName = resultBody.corporateName;
      this.companyName = resultBody.companyName;
      this.phone = resultBody.phone;
      this.mail = resultBody.mail;
      this.name = resultBody.name;
      this.zip = resultBody.zip;
      this.address = resultBody.address;
      this.info = resultBody.info;
      this.country = resultBody.country;
      this.selected = resultBody.typeId;
      this.payMethod = resultBody.payMethod;
    } catch (e) {
      console.log(e)
    }
  }
  ,
  methods: {
    async updateCompany() {
      this.loading = true;
      if (isEmpty(this.corporateName) || isEmpty(this.companyName)) {
        alert("必要な情報を入力してください。");
        return;
      }

      try {
        await updateCompany({
          id: this.$route.params.id.toString(),
          typeId: this.selected.toString(),
          corporateName: this.corporateName,
          companyName: this.companyName,
          phone: this.phone,
          mail: this.mail,
          name: this.name,
          zip: this.zip,
          address: this.address,
          info: this.info,
          country: this.country,
          updateName: store.state.userInfo.userName,
          payMethod: this.payMethod
        })
        alert("会社新規成功しました。");
        await this.$router.push({
          name: 'company-view'
        })
      } catch (e) {
        alert("会社新規失敗しました。");
      }
      this.loading = false;
    },
    async backToList() {
      await this.$router.push({
        name: 'company-view'
      })
    }
  }
}
</script>

<style scoped>
.web-title {
  font-size: 18px;
}

.red-asterisk {
  color: red;
}
</style>
