<template>
  <with-side-menu>
    <v-container fluid>
      <v-row justify="start" align="center" dense>
        <v-col cols="2">
          <h1>カテゴリ一覧</h1>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="3">
          <v-text-field dense
                        label="カテゴリ"
                        v-model="categoryName"
                        hide-details
                        outlined
          ></v-text-field>
        </v-col>
        <v-col cols="3">
          <v-text-field dense
                        label="ブランド"
                        v-model="brandName"
                        hide-details
                        outlined
          ></v-text-field>
        </v-col>
        <v-col cols="3">
          <v-btn
              color="mainColor"
              class="white--text button"
              style="width: 150px"
              :loading="searchFlag"
              :disabled="searchFlag"
              @click="searchCategory()"
          >検索
          </v-btn>
        </v-col>
      </v-row>
      <v-row align="center" justify="center" style="margin-top: 70px">
        <CategoryTable :items="searchDate"></CategoryTable>
      </v-row>
    </v-container>
  </with-side-menu>
</template>

<script>
import WithSideMenu from "@/components/WithSideMenu";
import {searchCategory} from "@/api/category";
import CategoryTable from "@/components/table/CategoryTable.vue";
import {isEmpty} from "@/utils/common";


export default {
  name: "CategoryView",
  components: {
    WithSideMenu,
    CategoryTable
  },
  data() {
    return {
      categoryName: "",
      brandName: "",
      searchFlag: false,
      searchDate: []
    }
  },
  async mounted() {
    try {
      let result = await searchCategory({});
      this.searchDate = result.data.result;
    } catch (e) {
      alert("初期データ取得失敗しました。")
    }
  },
  methods: {

    async searchCategory() {
      this.searchFlag = true;
      try {
        let result = await searchCategory({
          categoryName: isEmpty(this.categoryName) ? null : this.categoryName,
          brandName: isEmpty(this.brandName) ? null : this.brandName
        });
        this.searchDate = result.data.result;
      } catch (e) {
        alert("データ取得失敗しました。")
      }
      this.searchFlag = false;
    }
  }
}
</script>

<style scoped>

</style>
