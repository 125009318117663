<template>
  <with-side-menu>
    <v-container fluid>
      <v-row justify="start" align="center" dense>
        <v-col cols="2">
          <h1>発注入庫調整</h1>
        </v-col>
      </v-row>
      <v-row align="center" justify="center">
        <v-col cols="3">
          会社名:{{ this.$route.params.item.companyName }}
        </v-col>
        <v-col cols="3">
          発注書番号:{{ this.$route.params.item.requestNum }}
        </v-col>
        <v-col cols="3">
          <v-btn
              color="mainColor"
              class="white--text button"
              style="width: 150px"
              :loading="searchFlag"
              :disabled="searchFlag"
              @click="updateStock()"
          >保存
          </v-btn>
        </v-col>
        <v-col cols="3">
          <v-btn
              color="mainColor"
              class="white--text button"
              style="width: 250px"
              @click="changeNumber()"
          >全部入荷
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-data-table
              style="width: 100%"
              dense
              :headers="headers"
              :loading="this.isLoading"
              :items="items"
              :items-per-page="20"
              item-key="id"
              :footer-props="{
      'items-per-page-options': [20, 50, 100],
      showFirstLastPage: true,
      firstIcon: 'mdi-arrow-collapse-left',
      lastIcon: 'mdi-arrow-collapse-right',
      prevIcon: 'mdi-arrow-left',
      nextIcon: 'mdi-arrow-right'
    }"
          >
            <template v-slot:body="{ items }">
              <tbody>
              <tr v-if="isLoading">
                <td></td>
              </tr>
              <tr
                  v-for="(item,index) in items"
                  :key="index"
              >
                <td>
                  {{ item.jancode }}
                </td>
                <td>
                  {{ item.goodName }}
                </td>
                <td>
                  {{ item.number }}
                </td>
                <td>
                  <v-text-field
                      outlined
                      v-model="item.transactionDate"
                      dense
                      solo
                      flat
                      hide-details
                  ></v-text-field>
                </td>
                <td>
                  <v-text-field
                      outlined
                      v-model="item.inNumber"
                      dense
                      solo
                      flat
                      hide-details
                  ></v-text-field>
                </td>
              </tr>
              </tbody>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
  </with-side-menu>
</template>

<script>
import WithSideMenu from "@/components/WithSideMenu";
import {searchStock, addStock, updateStock} from "@/api/stock";
import store from "@/store";

export default {
  name: "TopPage",
  components: {
    WithSideMenu
  },
  data() {
    return {
      searchFlag: false,
      headers: [
        {
          "text": "JANコード",
          "value": "jancode",
          "class": "tableHeader white--text",
          "width": "100px"
        },
        {
          "text": "品名",
          "value": "goodName",
          "align": "start",
          "class": "tableHeader white--text",
          "width": "400px"
        },
        {
          "text": "発注数量",
          "value": "number",
          "class": "tableHeader white--text",
          "width": "80px"
        },
        {
          "text": "入荷日/出荷日",
          "value": "transactionDate",
          "class": "tableHeader white--text",
          "width": "80px"
        },
        {
          "text": "現在入庫数量",
          "value": "inNumber",
          "class": "tableHeader white--text",
          "width": "80px"
        }
      ],
      isLoading: false,
      items: []
    }
  },
  async mounted() {
    this.isLoading = true;
    const requestBody = JSON.parse(this.$route.params.item.requestBody);
    const relativeNumber = this.$route.params.item.requestNum;
    const response = await searchStock({
      relativeNumber: relativeNumber
    })
    const tempResult = response.data.result;
    for (let i = 0; i < requestBody.length; i++) {
      let matchedItem = tempResult.find(item => item.jancode === requestBody[i].jancode);
      let inNumberValue = matchedItem ? matchedItem.quantity : 0;
      let id = matchedItem ? matchedItem.id : null;
      let transactionDate = matchedItem ? matchedItem.transactionDate.substr(0, 10) : `${new Date().getFullYear()}-${(new Date().getMonth() + 1).toString().padStart(2, '0')}-${new Date().getDate().toString().padStart(2, '0')}`;

      this.items.push({
        id: id,
        price: requestBody[i].price,
        updateFlag: matchedItem,
        transactionDate: transactionDate,
        jancode: requestBody[i].jancode,
        goodName: requestBody[i].goodName,
        number: requestBody[i].number,
        inNumber: inNumberValue
      })
    }
    this.isLoading = false;
  },
  methods: {
    changeNumber() {
      for (let i = 0; i < this.items.length; i++) {
        this.items[i].inNumber = this.items[i].number;
      }
    },
    async updateStock() {
      this.searchFlag = true;
      for (let i = 0; i < this.items.length; i++) {
        if (this.items[i].id == null) {
          await addStock({
            addFlag: true,
            jancode: this.items[i].jancode,
            transactionType: 1,
            quantity: Number(this.items[i].inNumber),
            companyName: this.$route.params.item.companyName,
            relativeNumber: this.$route.params.item.requestNum,
            priceIncludingTax: String(this.items[i].price),
            transactionDate: this.items[i].transactionDate,
            userName: store.state.userInfo.userName
          })
        } else {
          await addStock({
            addFlag: false,
            jancode: this.items[i].jancode,
            id: Number(this.items[i].id),
            transactionType: 1,
            quantity: Number(this.items[i].inNumber),
            companyName: this.$route.params.item.companyName,
            relativeNumber: this.$route.params.item.requestNum,
            priceIncludingTax: String(this.items[i].price),
            transactionDate: this.items[i].transactionDate,
            userName: store.state.userInfo.userName
          })
        }
      }


      let status0Count = 0;
      let status2Count = 0;

      for (let i = 0; i < this.items.length; i++) {
        let item = this.items[i];
        let inNumber = parseInt(item.inNumber);
        let number = parseInt(item.number);

        if (inNumber === 0) {
          status0Count++;
        } else if (inNumber === number) {
          status2Count++;
        }
      }

      let status;
      if (status0Count === this.items.length) {
        status = "0";
      } else if (status2Count === this.items.length) {
        status = "2";
      } else {
        status = "1";
      }

      await updateStock({
        requestNum: this.$route.params.item.requestNum,
        stockStatus: status,
        updateName: store.state.userInfo.userName
      });

      this.searchFlag = false;
      alert("更新成功しました。")
      await this.$router.push({name: "HcyuView"});
    }
  }
}
</script>

<style scoped>

</style>