<template>
  <v-data-table
      style="width: 100%"
      dense
      :headers="headers"
      :loading="this.isLoading"
      :items="items"
      :items-per-page="20"
      item-key="id"
      :footer-props="{
      'items-per-page-options': [20, 50, 100],
      showFirstLastPage: true,
      firstIcon: 'mdi-arrow-collapse-left',
      lastIcon: 'mdi-arrow-collapse-right',
      prevIcon: 'mdi-arrow-left',
      nextIcon: 'mdi-arrow-right'
    }"
  >
    <template v-slot:body="{ items }">
      <tbody>
      <tr v-if="isLoading">
        <td></td>
      </tr>
      <tr
          v-for="item in items"
          :key="item.id"
      >
        <td>{{ item.jancode }}</td>
        <td>{{ item.nameJp }}</td>
        <td>{{ item.categoryName }}</td>
        <td>{{ item.brandName }}</td>
        <td>{{ item.priceExcludingTax }}</td>
        <td>{{ item.stockNumber }}</td>
        <td>
          <v-icon
              small
              @click="update(item.jancode,item.nameJp,item.priceExcludingTax)"
          >
            mdi-fountain-pen-tip
          </v-icon>
        </td>
        <td>
          <v-icon
              small
              @click="detaiShow(item.jancode,item.nameJp)"
          >
            mdi-eye
          </v-icon>
        </td>
      </tr>
      </tbody>
    </template>
  </v-data-table>
</template>

<script>

export default {
  name: "StockTable",
  props: {
    items: Array,
    isLoading: Boolean
  },
  data() {
    return {
      headers: [
        {
          "text": "JANコード",
          "value": "jancode",
          "class": "tableHeader white--text",
        },
        {
          "text": "商品名(日)",
          "value": "nameJp",
          "align": "start",
          "class": "tableHeader white--text"
        },
        {
          "text": "カテゴリー",
          "value": "categoryName",
          "class": "tableHeader white--text",
        },
        {
          "text": "ブランド",
          "value": "brandName",
          "class": "tableHeader white--text",
        },
        {
          "text": "定価（税抜）",
          "value": "priceExcludingTax",
          "class": "tableHeader white--text",
        },
        {
          "text": "在庫数",
          "value": "stockNumber",
          "class": "tableHeader white--text",
          "width": "300px"
        },
        {
          "text": "調整",
          "value": "",
          "class": "tableHeader white--text",
          "width": "200"
        },
        {
          "text": "詳細",
          "value": "",
          "class": "tableHeader white--text",
          "width": "200"
        }
      ]
    }
  },
  methods: {
    async update(jancode,goodName,priceExcludingTax) {
      await this.$router.push({
        name: 'AdminStockEdit',
        params: {
          jancode: jancode,
          goodName: goodName,
          priceExcludingTax:priceExcludingTax
        }
      })
    },
    async detaiShow(jancode,goodName) {
      await this.$router.push({
        name: 'StockDetailShow',
        params: {
          jancode: jancode,
          goodName: goodName
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
