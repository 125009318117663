import axios from "axios";
import store from "@/store";


// export const baseURL = "http://localhost:8888/";
// export const imageURL = "http://localhost/images/";
// // lambda用のAxiosWrapper
// export const axiosWrapper = axios.create({
//     baseURL: "http://localhost:8888/",
//     timeout: 29000
// });
export const baseURL = "http://103.30.41.135:81/";
export const imageURL = "http://103.30.41.135:82/";
// lambda用のAxiosWrapper
export const axiosWrapper = axios.create({
    baseURL: baseURL,
    timeout: 29000
});

export const getWithToken = async (path, config = {}) => {
    let headers = {token: store.state.userInfo.accessToken}
    config.headers = {
        ...config.headers,
        ...headers
    };
    try {
        return await axiosWrapper.get(path, config);
    } catch (error) {
        console.log(error);
    }
};
//
export const postWithToken = async (path, orgData, config = {}) => {
    let headers = {token: store.state.userInfo.accessToken}
    config.headers = {
        ...config.headers,
        ...headers
    };
    // bodyにログ用のデータを追加する
    try {
        return await axiosWrapper.post(path, orgData, config);
    } catch (error) {
        console.log(error)
    }
};


export const post = async (path, orgData, config = {}) => {
    return await axiosWrapper.post(path, orgData, config);
};

