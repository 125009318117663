export const isEmpty = str => !str || str.length === 0;


export const formatCurrentDate = () => {
    const now = new Date();
    const year = now.getFullYear().toString().substring(2);
    const month = (now.getMonth() + 1).toString().padStart(2, '0');
    const day = now.getDate().toString().padStart(2, '0');
    const hour = now.getHours().toString().padStart(2, '0');
    const minute = now.getMinutes().toString().padStart(2, '0');
    return `${year}${month}${day}${hour}${minute}`;
}


// export const fixPrice = (price) => {
//     const result = [];
//     let counter = 0;
//     price = (price || 0).toString().split('')
//     for (let i = price.length - 1; i >= 0; i--) {
//         counter++;
//         result.unshift(price[i])
//         if (!(counter % 3) && i != 0) {
//             result.unshift(',')
//         }
//     }
//     return result.join('');
// }

export const fixPrice = (price) => {
    if (isNaN(price)) return '0';

    const [integerPart, decimalPart] = price.toString().split('.');
    const isNegative = integerPart[0] === '-';
    let integerDigits = isNegative ? integerPart.slice(1) : integerPart;
    const result = [];
    let counter = 0;

    for (let i = integerDigits.length - 1; i >= 0; i--) {
        counter++;
        result.unshift(integerDigits[i]);
        if (counter % 3 === 0 && i !== 0) {
            result.unshift(',');
        }
    }

    if (isNegative) {
        result.unshift('-');
    }

    if (decimalPart) {
        result.push('.');
        result.push(decimalPart);
    }

    return result.join('');
}

export const getStockStatusColor = (str) => {
    if (Number(str) === 0) {
        return "red"
    }else if(Number(str) === 1){
        return "orange"
    }else {
        return "black"
    }
}

export const showStockStatusString = (str) => {
    if (Number(str) === 0) {
        return "入荷待ち"
    }else if(Number(str) === 1){
        return "一部入荷"
    }else {
        return "入荷完了"
    }
}

export const showStockStatusStringR = (str) => {
    if (Number(str) === 0) {
        return "出荷待ち"
    }else if(Number(str) === 1){
        return "一部出荷"
    }else {
        return "出荷完了"
    }
}



export const showPayStatusString = (str) => {
    if (Number(str) === 0) {
        return "入金待ち"
    }else if(Number(str) === 1){
        return "一部入金"
    }else {
        return "入金完了"
    }
}

export const showPayStatusStringH = (str) => {
    if (Number(str) === 0) {
        return "出金待ち"
    }else if(Number(str) === 1){
        return "一部出金"
    }else {
        return "出金完了"
    }
}