<template>
  <with-side-menu>
    <v-container fluid>
      <v-row>
        <v-col cols="12">
          <v-row class="ml-3 mb-3">
            <h1>CSVファイル導入</h1>
          </v-row>
          <v-row class="ml-3 mb-3">
            <h3>STEP 1</h3>
          </v-row>
          <v-row class="ml-10 mb-3">
            <v-col>
              テンプレートファイルを先に用意してください。→<a href="http://103.215.80.59:82/template.csv"
                                                             download="preset_file.txt">CSVテンプレート</a>
            </v-col>
          </v-row>
          <v-row class="ml-3 mb-3">
            <h3>STEP 2</h3>
          </v-row>
          <v-row class="ml-10 mb-3">
            <v-col cols="4">
              商品画像をアップロードするには貰うの画像KEYを上記テンプレート中画像の所を貼り付け<br>★画像複数枚アップロード可能ですか、一枚つつのKEYを記録してください。<br>★該当商品画像記入しない場合はdefault.pngを使います。→
            </v-col>
            <v-col cols="2">
              <v-file-input
                  label="画像選択"
                  accept=".jpeg,.jpg,.png"
                  @change="selectedFile"
                  v-model="imgUpload"></v-file-input>
            </v-col>
            <v-col cols="3">
              <img
                  v-for="(image, index) in inputImage"
                  :key="index"
                  width="200px"
                  height="200px"
                  :src="image.fileUrl"
                  alt="insertGood"/>
            </v-col>
            <v-col cols="2">
              <v-btn class=" white--text button" color="mainColor" @click="uploadImage" :loading="loading"
              >画像アップロード
              </v-btn>
            </v-col>
          </v-row>
          <v-row class="ml-10 mb-3">
            <v-col cols="4">
              アップロードの画像KRYは<p style="color: #DA0348">{{ imageKey }}</p>
            </v-col>
            <v-col cols="2">
              <v-btn @click="copyValue" width="120px" class=" white--text button" color="pink"
              >コピー
              </v-btn>
            </v-col>
          </v-row>
          <v-row class="ml-3 mb-3">
            <h3>STEP 3</h3>
          </v-row>
          <v-row class="ml-10 mb-3">
            <v-col cols="4">
            上記処理後のCSVファイルアップロードする<br>★会社ことで導入してください。（仕入れ会社名は固定一つ）
            </v-col>
            <v-col cols="4">
              <v-file-input v-model="csvFile" label="CSVファイル選択" accept=".csv"></v-file-input>
            </v-col>
            <v-col cols="3">
              <v-btn @click="uploadCSV" class=" white--text button" color="mainColor" :loading="loading">
                CSVアップロード
              </v-btn>
            </v-col>
          </v-row>
          <v-row class="ml-10 mr-10 mb-3">
            <v-textarea height="300px" outlined :readonly="true" v-model="errorMsg">
            </v-textarea>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </with-side-menu>
</template>

<script>
import WithSideMenu from "@/components/WithSideMenu";
import {baseURL} from "@/api/RestClient";
import axios from "axios";
import store from "@/store";
import {compression, getDataUrl} from "@/utils/image";


export default {
  name: "ImportGoodCSV",
  components: {
    WithSideMenu
  },
  data() {
    return {
      csvFile: null,
      imgUpload: null,
      inputImage: [],
      loading: false,
      imageKey: "",
      errorMsg: "エラー情報："
    }
  },
  methods: {
    copyValue() {
      const textarea = document.createElement('textarea');
      textarea.value = this.imageKey;
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand('copy');
      document.body.removeChild(textarea);
    },
    async selectedFile(file) {
      const newFiles = [];
      if (file != null) {
        newFiles.push(new File([file], file.name, {type: file.type}));
        try {
          // 今まで入力された画像を削除する
          this.inputImage.splice(0, this.inputImage.length);
          newFiles.map(async file => {
            const compressedFile = await compression(file);
            const fileUrl = await getDataUrl(compressedFile);
            this.inputImage.push({
              blob: compressedFile,
              fileUrl,
              fileName: file.name
            });
          });
        } catch (error) {
          console.log(error)
        }
      } else {
        this.inputImage = [];
      }
    },
    async uploadCSV() {
      this.loading = true;
      try {
        const file = this.csvFile;
        let formData = new FormData();
        formData.append('file', file);
        formData.append('username', store.state.userInfo.userName)
        const headers = {
          'token': store.state.userInfo.accessToken,
        };
        const res = await axios.post(baseURL + "author/ImportGood", formData, {headers})
        if (res.data.importResult === "success") {
          alert("データ導入成功しました。")
          location.reload()
        } else {
          this.errorMsg = this.errorMsg + res.data.error;
          alert("データ導入失敗しました。以下のエラー情報所確認してください。")
        }
      } catch (error) {
        alert("データ導入失敗しました。以下のエラー情報所確認してください。")
      } finally {
        this.loading = false;
      }

    },
    async uploadImage() {
      this.loading = true;
      try {
        const file = this.imgUpload;
        let formData = new FormData();
        formData.append('file', file);
        const headers = {
          'token': store.state.userInfo.accessToken,
        };
        const res = await axios.post(baseURL + "author/ImageUpload", formData, {headers})
        this.imageKey = res.data;
      } catch (error) {
        console.log(error)
      }
      this.loading = false;
    }
  }


}
</script>

<style scoped>

</style>
