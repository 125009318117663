<template>
  <with-side-menu>
    <v-container fluid>
      <v-row>
        <v-col cols="12">
          <v-row class="ml-3 mb-3">
            <h1>PDF発注書登録(コピー)</h1>
          </v-row>
          <v-row dense align="center">
            <v-col cols="12">
              <v-row align="center">
                <v-col cols="1">
                  <p class="web-title">仕入先名：</p>
                </v-col>
                <v-col cols="2">
                  <v-autocomplete
                      v-model="selectedItem"
                      :items="itemSelect"
                      label="請求会社を選択してください。"
                      item-text="companyName"
                      item-value="companyName"
                      return-object
                      clearable
                      :search-input.sync="search"
                      @change="onSelectionChanged"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                      label="請求日"
                      v-model="date"
                      hide-details
                      outlined
                      dense
                  ></v-text-field>
                </v-col>
                <v-col cols="1">
                  <v-menu
                      offset-y
                      v-model="menu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                  >
                    <template v-slot:activator="{ on, attrs}">
                      <v-icon large color="mainColor" v-bind="attrs" v-on="on">
                        mdi-calendar-month-outline
                      </v-icon>
                    </template>
                    <v-date-picker
                        v-model="date"
                        no-title
                        @input="menu = false"
                        locale="ja_jp"
                    >
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                      label="希望納品日"
                      v-model="date1"
                      hide-details
                      outlined
                      dense
                  ></v-text-field>
                </v-col>
                <v-col cols="1">
                  <v-menu
                      offset-y
                      v-model="menu1"
                      :close-on-content-click="false"
                      transition="scale-transition"
                  >
                    <template v-slot:activator="{ on, attrs}">
                      <v-icon large color="mainColor" v-bind="attrs" v-on="on">
                        mdi-calendar-month-outline
                      </v-icon>
                    </template>
                    <v-date-picker
                        v-model="date1"
                        no-title
                        @input="menu1 = false"
                        locale="ja_jp"
                    >
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-row>
                  <v-col cols="2">
                    <v-radio-group v-model="faxFlag" row>
                      <v-radio label="税拔" :value="0"></v-radio>
                      <v-radio label="税込" :value="1"></v-radio>
                    </v-radio-group>
                  </v-col>
                  <v-col cols="2">
                    <v-btn width="220px" class=" white--text button" color="mainColor"
                            @click="getDataFormSP"
                    >再計算
                    </v-btn>
                  </v-col>
                  <v-col cols="2">
                    <v-text-field dense outlined v-model="fare" label="送料">

                    </v-text-field>
                  </v-col>
                  <v-col cols="2">
                    <v-text-field dense outlined v-model="adjust" label="調整金額">

                    </v-text-field>
                  </v-col>
                  <v-col cols="2">
                    <v-btn width="220px" class=" white--text button" color="mainColor"
                           :disabled="this.selectedItem == null" @click="getDataForm"
                    >テーブルデータ表示
                    </v-btn>
                  </v-col>
                  <v-col cols="2">
                    <v-btn :disabled="this.items.length<=0" width="160px" class=" white--text button" color="mainColor"
                           @click="saveData"
                    >発注書登録
                    </v-btn>
                  </v-col>
                </v-row>
              </v-row>
            </v-col>
            <v-col cols="4">
              <v-textarea outlined label="JANCODE入力" v-model="textBody">

              </v-textarea>
            </v-col>
            <v-col cols="2">
              <v-textarea outlined label="数量入力" v-model="numberTextBody">

              </v-textarea>
            </v-col>
            <v-col cols="4">
              <v-textarea outlined label="備考（PDF表示しない）" v-model="note">

              </v-textarea>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="2">
          <v-checkbox v-model="lockFlag" dense label="掛率使用"
          >
          </v-checkbox>
        </v-col>
        <v-col cols="3">
          <v-text-field
              label="掛率"
              v-model="targetCutOff"
              hide-details
              outlined
              dense
          ></v-text-field>
        </v-col>
        <v-col cols="3">
          <v-btn width="220px" class=" white--text button" color="mainColor"
                 @click="changeCutOff"
          >掛率一気入力
          </v-btn>
        </v-col>
      </v-row>
      <v-row align="center" justify="center" style="margin-top: 70px">
        <v-data-table
            style="width: 100%"
            dense
            :headers="headers"
            :loading="this.isLoading"
            :items="items"
            :items-per-page="20"
            item-key="id"
            :footer-props="{
      'items-per-page-options': [20, 50, 100],
      showFirstLastPage: true,
      firstIcon: 'mdi-arrow-collapse-left',
      lastIcon: 'mdi-arrow-collapse-right',
      prevIcon: 'mdi-arrow-left',
      nextIcon: 'mdi-arrow-right'
    }"
        >
          <template v-slot:body="{ items }">
            <tbody>
            <tr v-if="isLoading">
              <td></td>
            </tr>
            <tr
                v-for="(item,index) in items"
                :key="index"
            >
              <td>
                {{ item.jancode }}
              </td>
              <td>
                {{ item.goodName }}
              </td>
              <td>
                {{ item.faxRate + "%" }}
              </td>
              <td>
                {{ item.caseQuantity }}
              </td>
              <td>
                <v-text-field
                    outlined
                    v-model="item.number"
                    dense
                    solo
                    flat
                    hide-details
                ></v-text-field>
              </td>
              <td>
                {{ item.string }}
              </td>
              <td>
                {{ item.inPrice }}
              </td>
              <td>
                <v-text-field
                    :disabled="!lockFlag"
                    outlined
                    v-model="item.cutOff"
                    dense
                    solo
                    flat
                    hide-details
                ></v-text-field>
              </td>
              <td>
                <v-text-field
                    outlined
                    v-model="item.price"
                    dense
                    solo
                    flat
                    hide-details
                ></v-text-field>
              </td>
              <td>
                {{ Number(item.number) * Number(item.price) }}
              </td>
              <td>
                <v-icon
                    @click="deleteItem(index)">
                  mdi-delete
                </v-icon>
              </td>
            </tr>
            </tbody>
          </template>
        </v-data-table>
      </v-row>
    </v-container>
  </with-side-menu>
</template>

<script>
import WithSideMenu from "@/components/WithSideMenu";
import {getCompanyList} from "@/api/company";
import {getGoodList} from "@/api/good";
import {formatCurrentDate} from "@/utils/common";
import store from "@/store";
import {insertHcyu} from "@/api/pdf";

export default {
  name: "PdfRequest",
  components: {
    WithSideMenu
  },
  data() {
    return {
      headers: [
        {
          "text": "JANコード",
          "value": "jancode",
          "class": "tableHeader white--text",
          "width": "100px"
        },
        {
          "text": "品名",
          "value": "goodName",
          "align": "start",
          "class": "tableHeader white--text",
          "width": "400px"
        },
        {
          "text": "税率",
          "value": "faxRate",
          "align": "start",
          "class": "tableHeader white--text",
          "width": "80px"
        },
        {
          "text": "入り数",
          "value": "caseQuantity",
          "class": "tableHeader white--text",
          "width": "80px"
        },
        {
          "text": "数量",
          "value": "number",
          "class": "tableHeader white--text",
          "width": "80px"
        },
        {
          "text": "単位",
          "value": "string",
          "class": "tableHeader white--text",
          "width": "80px"
        },
        {
          "text": "定価",
          "value": "inPrice",
          "class": "tableHeader white--text",
          "width": "100px"
        },
        {
          "text": "掛率%",
          "value": "cutOff",
          "class": "tableHeader white--text",
          "width": "80px"
        },
        {
          "text": "単価",
          "value": "price",
          "class": "tableHeader white--text",
          "width": "100px"
        },
        {
          "text": "金額",
          "value": "",
          "class": "tableHeader white--text",
          "width": "100px"
        },
        {
          "text": "削除",
          "value": "",
          "class": "tableHeader white--text",
          "width": "80px"
        }
      ],
      textBody: null,
      items: [],
      numberTextBody: null,
      itemSelect: [],
      isLoading: false,
      selectedItem: null,
      search: null,
      zip: null,
      address: null,
      phone: null,
      menu: false,
      menu1: false,
      fare: 0,
      adjust: 0,
      faxFlag: 0,
      targetCutOff: null,
      note: null,
      lockFlag: false,
      date: `${new Date().getFullYear()}-${(new Date().getMonth() + 1).toString().padStart(2, '0')}-${new Date().getDate().toString().padStart(2, '0')}`,
      date1: `${new Date().getFullYear()}-${(new Date().getMonth() + 1).toString().padStart(2, '0')}-${new Date().getDate().toString().padStart(2, '0')}`
    }
  },
  watch: {
    items: {
      handler: 'refreshPrice',
      deep: true,
    }
  },
  methods: {
    changeCutOff() {
      this.items.forEach(item => {
        item.cutOff = this.targetCutOff;
      });
      this.refreshPrice()
    },
    refreshPrice() {
      if (this.lockFlag) {
        this.items.forEach(item => {
          if (!isNaN(item.cutOff) && !isNaN(item.inPrice)) {
            const discountRate = Number(item.cutOff) / 100;
            item.price = Math.round(Number(item.inPrice) * discountRate);
          }
        });
      }
    },
    deleteItem(index) {
      this.items.splice(index, 1);
    },
    onSelectionChanged(value) {
      this.address = value.address;
      this.phone = value.phone;
      this.zip = value.zip;
    },
    async getDataFormSP(){
      this.items = [];
      const waitProList = this.textBody ? this.textBody.split('\n') : [];
      const numberText = this.numberTextBody ? this.numberTextBody.split('\n') : [];
      const flag = waitProList.length === numberText.length
      for (let i = 0; i < waitProList.length; i++) {
        if (waitProList[i] != null && waitProList[i] !== "") {
          let detail = await getGoodList({jancode: waitProList[i]})
          let tempData = detail.data.result[0]
          let tanSportPrice = null;
          if (this.selectedItem.companyName != null) {
            let companyPriceList = JSON.parse(tempData.tansportDetail)
            for (let k = 0; k < companyPriceList.length; k++) {
              if (companyPriceList[k].tanSportCompanyName === this.selectedItem.companyName) {
                tanSportPrice = companyPriceList[k].tanSportPrice
              }
            }
          }
          if(Number(this.faxFlag)===0){
            let jsonBody = {
              jancode: tempData.jancode,
              goodName: tempData.nameJp,
              faxRate: tempData.faxRate,
              number: flag ? numberText[i] : 1,
              string: '個',
              price: tanSportPrice == null ? tempData.priceExcludingTax : tanSportPrice,
              inPrice: tempData.priceExcludingTax,
              cutOff: 100,
              pricePlus: tanSportPrice == null ? (flag ? numberText[i] : 1) * Number(tanSportPrice) : (flag ? numberText[i] : 1) * Number(tempData.priceExcludingTax),
              caseQuantity: tempData.caseQuantity
            }
            this.items.push(jsonBody)
          }else {
            let jsonBody = {
              jancode: tempData.jancode,
              goodName: tempData.nameJp,
              faxRate: tempData.faxRate,
              number: flag ? numberText[i] : 1,
              string: '個',
              price: tanSportPrice == null ? Math.round(tempData.priceExcludingTax*(1+Number(tempData.faxRate)/100)) : Math.round(tanSportPrice*(1+Number(tempData.faxRate)/100)),
              inPrice: Math.round(tempData.priceExcludingTax*(1+Number(tempData.faxRate)/100)),
              cutOff: 100,
              pricePlus: tanSportPrice == null ? (flag ? numberText[i] : 1) * Math.round(tanSportPrice*(1+Number(tempData.faxRate)/100)) : (flag ? numberText[i] : 1) * Math.round(tempData.priceExcludingTax*(1+Number(tempData.faxRate)/100)),
              caseQuantity: tempData.caseQuantity
            }
            this.items.push(jsonBody)
          }
        }
      }
    },
    async getDataForm() {
      this.items = [];
      const waitProList = this.textBody ? this.textBody.split('\n') : [];
      const numberText = this.numberTextBody ? this.numberTextBody.split('\n') : [];
      const flag = waitProList.length === numberText.length
      for (let i = 0; i < waitProList.length; i++) {
        if (waitProList[i] != null && waitProList[i] !== "") {
          let detail = await getGoodList({jancode: waitProList[i]})
          let tempData = detail.data.result[0]
          let tanSportPrice = null;
          if (this.selectedItem.companyName != null) {
            let companyPriceList = JSON.parse(tempData.tansportDetail)
            for (let k = 0; k < companyPriceList.length; k++) {
              if (companyPriceList[k].tanSportCompanyName === this.selectedItem.companyName) {
                tanSportPrice = companyPriceList[k].tanSportPrice
              }
            }
          }
          let jsonBody = {
            jancode: tempData.jancode,
            goodName: tempData.nameJp,
            faxRate: tempData.faxRate,
            number: flag ? numberText[i] : 1,
            string: '個',
            price: tanSportPrice == null ? tempData.priceExcludingTax : tanSportPrice,
            inPrice: tempData.priceExcludingTax,
            cutOff: 100,
            pricePlus: tanSportPrice == null ? (flag ? numberText[i] : 1) * Number(tanSportPrice) : (flag ? numberText[i] : 1) * Number(tempData.priceExcludingTax),
            caseQuantity: tempData.caseQuantity
          }
          this.items.push(jsonBody)
        }
      }
    },
    async saveData() {
      if (this.selectedItem.companyName == null || this.selectedItem.companyName === "") {
        alert("会社選択してください。")
        return;
      }

      let jsonBody = this.items.map(item => ({
        jancode: item.jancode,
        goodName: item.goodName,
        faxRate: item.faxRate,
        number: Number(item.number),
        string: item.string,
        price: Number(item.price),
        totalPrice: Number(item.number) * Number(item.price)
      }));
      let totalPricePlus = 0;
      let totalPriceE = 0;
      let totalPriceT = 0;
      let totalFaxE = 0;
      let totalFaxT = 0;
      if (Number(this.faxFlag) === 0) {
        for (let i = 0; i < this.items.length; i++) {
          if (Number(this.items[i].faxRate) === 10) {
            totalPriceT = totalPriceT + Number(this.items[i].number) * Number(this.items[i].price);
          } else {
            totalPriceE = totalPriceE + Number(this.items[i].number) * Number(this.items[i].price);
          }
          totalPricePlus = totalPricePlus + Number(this.items[i].number) * Number(this.items[i].price);
        }

        totalPriceT = totalPriceT + Number(this.fare) + Number(this.adjust);
        totalPricePlus = totalPricePlus+ Number(this.fare) + Number(this.adjust);
        totalFaxT = Math.round(Number(totalPriceT) * 0.1);
        totalFaxE = Math.round(Number(totalPriceE) * 0.08);
        totalPricePlus = totalPricePlus + totalFaxT + totalFaxE;
      } else {
        for (let i = 0; i < this.items.length; i++) {
          if (Number(this.items[i].faxRate) === 10) {
            totalPriceT = totalPriceT + Number(this.items[i].number) * Number(this.items[i].price);
          } else {
            totalPriceE = totalPriceE + Number(this.items[i].number) * Number(this.items[i].price);
          }
          totalPricePlus = totalPricePlus + Math.round(Number(this.items[i].number) * Number(this.items[i].price));
        }
        totalPriceT = totalPriceT + Number(this.fare) + Number(this.adjust);
        totalPricePlus = totalPricePlus+ Number(this.fare) + Number(this.adjust);
        totalFaxT = Math.round(Number(totalPriceT) / 1.1 * 0.1);
        totalFaxE = Math.round(Number(totalPriceE) / 1.08 * 0.08);
      }

      const requestNum = "H" + this.selectedItem.id + formatCurrentDate();

      try {
        await insertHcyu({
          pdfType: "H",
          companyName: this.selectedItem.companyName,
          zip: this.zip,
          companyAddress: this.address,
          phone: this.phone,
          requestDate: this.date,
          receviceDate: this.date1,
          requestNum: requestNum,
          totalPrice: totalPricePlus.toString(),
          requestBody: JSON.stringify(jsonBody),
          totalFaxE: totalFaxE.toString(),
          totalPriceE: totalPriceE.toString(),
          totalFaxT: totalFaxT.toString(),
          totalPriceT: totalPriceT.toString(),
          fare: this.fare.toString(),
          adjust: this.adjust.toString(),
          faxFlag: Number(this.faxFlag),
          note: this.note,
          createName: store.state.userInfo.userName
        });
      } catch (e) {
        console.log(e)
        alert("発注書新規失敗しました。")
      }
      alert("発注書作成成功しました。番号：" + requestNum);
      await this.$router.push({name: "HcyuView"});
    }
  },
  async mounted() {
    const response = await getCompanyList({typeId: '1'});
    this.itemSelect = response.data.result;
    const item1 = this.$route.params.item;
    this.date1 = item1.receviceDate;
    this.fare = item1.fare;
    this.adjust = item1.adjust;
    this.note = item1.note;
    this.faxFlag = Number(item1.faxFlag);
    const copyData = JSON.parse(item1.requestBody);
    // this.items = copyData;
    let textBodyString = "";
    let textBodyNumber = "";
    for (let i = 0; i < copyData.length; i++) {
      textBodyString = textBodyString + copyData[i].jancode;
      textBodyNumber = textBodyNumber + copyData[i].number;
      if (i !== copyData.length) {
        textBodyString = textBodyString + "\n";
        textBodyNumber = textBodyNumber + "\n";
      }
    }
    this.textBody = textBodyString;
    this.numberTextBody = textBodyNumber;
    this.selectedItem = this.itemSelect.find(item => item.companyName === item1.companyName);
    this.address =  this.selectedItem.address;
    this.phone = this.selectedItem.phone;
    this.zip = this.selectedItem.zip;

    await this.getDataForm();
    this.items.forEach((itemObj) => {
      let currentJancode = itemObj.jancode;
      let matchingCopyData = copyData.find((copyDataObj) => {
        return copyDataObj.jancode === currentJancode;
      });
      if (matchingCopyData) {
        itemObj.price = matchingCopyData.price;
      }
    });

  }
}
</script>

<style scoped>
.web-title {
  font-size: 18px;
}
</style>
