<template>
  <with-side-menu>
    <v-container fluid>
      <v-row justify="start" align="center" dense>
        <v-col cols="2">
          <h1>在庫一覧</h1>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="3">
          <v-text-field dense
                        label="JANコード"
                        v-model="janCode"
                        hide-details
                        outlined
                        @keyup.enter="searchStocks"
          ></v-text-field>
        </v-col>
        <v-col cols="3">
          <v-text-field dense
                        label="商品名(日中英)"
                        v-model="goodName"
                        hide-details
                        outlined
                        @keyup.enter="searchStocks"
          ></v-text-field>
        </v-col>
        <v-col cols="3">
          <v-text-field dense
                        label="ブランド"
                        v-model="brandName"
                        hide-details
                        outlined
                        @keyup.enter="searchStocks"
          ></v-text-field>
        </v-col>
        <v-col cols="3">
          <v-checkbox dense v-model="filterZero" label="在庫0抜く"></v-checkbox>
        </v-col>

        <v-col cols="3">
          <v-btn
              color="mainColor"
              class="white--text button"
              style="width: 150px"
              :loading="searchFlag"
              :disabled="searchFlag"
              @click="searchStocks()"
          >検索
          </v-btn>
        </v-col>
        <v-col cols="2">
          <v-btn
              color="red"
              class="white--text button"
              style="width: 150px"
              @click="clear()"
          >クリア
          </v-btn>
        </v-col>
      </v-row>
      <v-row align="center" justify="center" style="margin-top: 70px">
        <StockNumberTable :items="filteredSearchDate"></StockNumberTable>
      </v-row>
    </v-container>
  </with-side-menu>
</template>

<script>
import WithSideMenu from "@/components/WithSideMenu";
import {getGoodList} from "@/api/good";
import {isEmpty} from "@/utils/common";
import StockNumberTable from "@/components/table/StockNumberTable.vue";


export default {
  name: "GoodView",
  components: {
    WithSideMenu,
    StockNumberTable
  },
  data() {
    return {
      goodName: "",
      janCode: "",
      categoryName: "",
      brandName: "",
      companyName: "",
      searchFlag: false,
      searchDate: [],
      filterZero: false
    }
  },
  async mounted() {
    try {
      let result = await getGoodList({});
      this.searchDate = result.data.result;
    } catch (e) {
      alert("初期データ取得失敗しました。")
    }
  },
  computed: {
    filteredSearchDate() {
      if (this.filterZero) {
        return this.searchDate.filter(item => item.stockNumber !== 0);
      } else {
        return this.searchDate;
      }
    }
  },
  methods: {
    clear() {
      this.janCode = "";
      this.goodName = "";
      this.brandName = "";
      this.filterZero = false;
    },
    async searchStocks() {
      this.searchFlag = true;
      try {
        let result = await getGoodList({
          jancode: isEmpty(this.janCode) ? null : this.janCode,
          goodName: isEmpty(this.goodName) ? null : this.goodName,
          categoryName: isEmpty(this.categoryName) ? null : this.categoryName,
          brandName: isEmpty(this.brandName) ? null : this.brandName,
          companyName: isEmpty(this.companyName) ? null : this.companyName
        });
        this.searchDate = result.data.result;
      } catch (e) {
        alert("データ取得失敗しました。")
      }
      this.searchFlag = false;
    }
  }
}
</script>

<style scoped>

</style>
