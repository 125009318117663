import {postWithToken} from "@/api/RestClient";
import {showDialog} from "@/utils/dialog";

export const insertCategory = async payload => {
    try {
        return postWithToken("author/category/insert", payload);
    } catch (error) {
        await showDialog("", error.response.data.message, "")
        throw error;
    }
};

export const searchCategory = async payload => {
    try {
        return postWithToken("author/category/categoryList", payload);
    } catch (error) {
        await showDialog("", error.response.data.message, "")
        throw error;
    }
};

export const deleteCategory = async payload => {
    try {
        return postWithToken("author/category/delete", payload);
    } catch (error) {
        await showDialog("", error.response.data.message, "")
        throw error;
    }
};

export const updateCategory = async payload => {
    try {
        return postWithToken("author/category/updateCategory", payload);
    } catch (error) {
        await showDialog("", error.response.data.message, "")
        throw error;
    }
};

