import {postWithToken} from "@/api/RestClient";
import {showDialog} from "@/utils/dialog";

export const insertPDF = async payload => {
    try {
        return postWithToken("author/request/insert", payload);
    } catch (error) {
        await showDialog("", error.response.data.message, "")
        throw error;
    }
};

export const insertHcyu = async payload => {
    try {
        return postWithToken("author/hcyu/insert", payload);
    } catch (error) {
        await showDialog("", error.response.data.message, "")
        throw error;
    }
};


export const updatePDF = async payload => {
    try {
        return postWithToken("author/request/update", payload);
    } catch (error) {
        await showDialog("", error.response.data.message, "")
        throw error;
    }
};

export const updateHcyu = async payload => {
    try {
        return postWithToken("author/hcyu/update", payload);
    } catch (error) {
        await showDialog("", error.response.data.message, "")
        throw error;
    }
};

export const searchRequest = async payload => {
    try {
        return postWithToken("author/request/search", payload);
    } catch (error) {
        await showDialog("", error.response.data.message, "")
        throw error;
    }
};

export const searchHcyu = async payload => {
    try {
        return postWithToken("author/hcyu/search", payload);
    } catch (error) {
        await showDialog("", error.response.data.message, "")
        throw error;
    }
};

export const deleteRequest = async payload => {
    try {
        return postWithToken("author/request/delete", payload);
    } catch (error) {
        await showDialog("", error.response.data.message, "")
        throw error;
    }
}

export const deleteHcyu = async payload => {
    try {
        return postWithToken("author/hcyu/delete", payload);
    } catch (error) {
        await showDialog("", error.response.data.message, "")
        throw error;
    }
}

export const openPdf = async payload => {
    try {
        return postWithToken("author/pdf/open", payload);
    } catch (error) {
        await showDialog("", error.response.data.message, "")
        throw error;
    }
}
