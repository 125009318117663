export const setHcyuCondition = (jsonBody) => {
    localStorage.setItem("Hcyu", jsonBody);
}

export const getHcyuCondition = () => {
    return localStorage.getItem("Hcyu")
}

export const setReqCondition = (jsonBody) => {
    localStorage.setItem("Req", jsonBody);
}

export const getReqCondition = () => {
    return localStorage.getItem("Req")
}