<template>
  <v-data-table
      style="width: 100%"
      dense
      :headers="headers"
      :loading="this.isLoading"
      :items="items"
      :items-per-page="20"
      item-key="id"
      :footer-props="{
      'items-per-page-options': [20, 50, 100],
      showFirstLastPage: true,
      firstIcon: 'mdi-arrow-collapse-left',
      lastIcon: 'mdi-arrow-collapse-right',
      prevIcon: 'mdi-arrow-left',
      nextIcon: 'mdi-arrow-right'
    }"
  >
    <template v-slot:body="{ items }">
      <tbody>
      <tr v-if="isLoading">
        <td></td>
      </tr>
      <tr
          v-for="item in items"
          :key="item.id"
      >
        <td>{{ item.requestDate }}</td>
        <td>{{ item.requestNum }}</td>
        <td>{{ item.companyName }}</td>
        <td>{{ fixPrice(item.totalPrice) }}</td>
        <td>{{ item.createUser }}</td>
        <td>{{ item.note }}</td>
        <td>{{ item.receviceDate }}</td>
        <td>
          <v-icon
              @click="preRev(item)"
          >
            mdi-file-find
          </v-icon>
          <v-icon
              class="pl-2"
              @click="edit(item)"
          >
            mdi-pencil
          </v-icon>
          <v-icon
              class="pl-2"
              @click="downloadPDF(item)"
          >
            mdi-arrow-down-bold
          </v-icon>
          <v-icon
              class="pl-2"
              @click="copy(item)"
          >
            mdi-content-copy
          </v-icon>
          <v-icon
              class="pl-2"
              @click="copyRequest(item)"
          >
            mdi-invoice-edit
          </v-icon>
        </td>
        <td :style="{ color: getStockStatusColor(item.stockStatus) }">
          <div style="cursor: pointer" @click="editStock(item)">{{ showStockStatusString(item.stockStatus) }}</div>
        </td>
        <td :style="{ color: getStockStatusColor(item.payStatus) }">
          <div style="cursor: pointer" @click="editPrice(item)">{{ showPayStatusStringH(item.payStatus) }}</div>
        </td>
        <td>
          <v-icon
              class="pl-2"
              @click="deleteItem(item.requestNum)"
          >
            mdi-delete
          </v-icon>
        </td>
      </tr>
      </tbody>
    </template>
  </v-data-table>
</template>

<script>
import {fixPrice, showPayStatusStringH, showStockStatusString,getStockStatusColor} from "@/utils/common";
import axios from "axios";
import {baseURL} from "@/api/RestClient";
import store from "@/store";
import {deleteHcyu} from "@/api/pdf";


export default {
  name: "CompanyTable",
  props: {
    items: Array,
    isLoading: Boolean
  },
  data() {
    return {
      headers: [
        {
          "text": "発注日",
          "value": "requestDate",
          "class": "tableHeader white--text",
          "width": "80px"
        },
        {
          "text": "発注書番号",
          "value": "requestNum",
          "class": "tableHeader white--text",
          "width": "40px"
        },
        {
          "text": "仕入先名",
          "value": "companyName",
          "class": "tableHeader white--text",
          "width": "150px"
        },
        {
          "text": "発注金額",
          "value": "",
          "class": "tableHeader white--text",
          "width": "80px"
        },
        {
          "text": "作成者",
          "value": "",
          "class": "tableHeader white--text",
          "width": "100px"
        },
        {
          "text": "メモ",
          "value": "note",
          "class": "tableHeader white--text",
          "width": "180px"
        },
        {
          "text": "納品期限",
          "value": "receviceDate",
          "class": "tableHeader white--text",
          "width": "80px"
        },
        {
          "text": "PDF操作",
          "value": "",
          "class": "tableHeader white--text",
          "width": "120px"
        },
        {
          "text": "入庫ステータス",
          "value": "stockStatus",
          "class": "tableHeader white--text",
          "width": "100px"
        },
        {
          "text": "出金ステータス",
          "value": "payStatus",
          "class": "tableHeader white--text",
          "width": "100px"
        },
        {
          "text": "削除",
          "value": "",
          "class": "tableHeader white--text",
          "width": "80px"
        }
      ],
    }
  },
  methods: {
    fixPrice, showStockStatusString, showPayStatusStringH,getStockStatusColor,
    async copyRequest(item){
      await this.$router.push({
        name: 'pdfRequestFromHcyu',
        params: {
          item: item
        }
      })
    },
    async copy(item) {
      await this.$router.push({
        name: 'pdfHCYUAdd',
        params: {
          item: item
        }
      })
    },
    async editPrice(item){
      await this.$router.push({
        name: 'EditMoney',
        params: {
          item: item
        }
      })
    },
    async editStock(item) {
      await this.$router.push({
        name: 'EditStock',
        params: {
          item: item
        }
      })
    },
    async edit(item) {
      await this.$router.push({
        name: 'HcyuRequestEdit',
        params: {
          item: item
        }
      })
    },
    async deleteItem(requestNum) {
      if (confirm("この発注書を削除してよろしでしょうか？")) {
        try {
          await deleteHcyu({
            requestNum: requestNum
          });
        } catch (e) {
          alert("データ取得失敗しました。")
        }
        alert("削除成功しました。")
        location.reload();
      }
    },
    async preRev(item) {
      const jsonBody = JSON.parse(item.requestBody);
      let tempBody = jsonBody.map(item => [
        item.jancode,
        item.goodName,
        item.faxRate + "%",
        item.number.toString(),
        item.string,
        fixPrice(item.price),
        fixPrice(item.totalPrice),
      ]);
      axios({
        method: 'post',
        url: baseURL + 'author/pdf/hcyu',
        data: {
          companyName: item.companyName,
          zip: item.zip,
          companyAddress: item.companyAddress,
          phone: item.phone,
          requestDate: item.requestDate,
          requestNum: item.requestNum,
          totalPrice: fixPrice(item.totalPrice),
          totalFaxE: fixPrice(item.totalFaxE),
          totalFaxT: fixPrice(item.totalFaxT),
          body: tempBody,
          totalPriceE: fixPrice(item.totalPriceE),
          totalPriceT: fixPrice(item.totalPriceT),
          receviceDate: item.receviceDate,
          fare: fixPrice(item.fare),
          adjust: fixPrice(item.adjust),
          faxFlag: Number(item.faxFlag)
        },
        responseType: 'blob',
        headers: {
          token: store.state.userInfo.accessToken
        }
      })
          .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data], {type: 'application/pdf'}));
            window.open(url, '_blank');
            window.URL.revokeObjectURL(url);
          })
          .catch((error) => console.error('Download error:', error));
    },
    async downloadPDF(item) {
      const jsonBody = JSON.parse(item.requestBody);
      let tempBody = jsonBody.map(item => [
        item.jancode,
        item.goodName,
        item.faxRate + "%",
        item.number.toString(),
        item.string,
        fixPrice(item.price),
        fixPrice(item.totalPrice),
      ]);
      axios({
        method: 'post',
        url: baseURL + 'author/pdf/hcyu',
        data: {
          companyName: item.companyName,
          zip: item.zip,
          companyAddress: item.companyAddress,
          phone: item.phone,
          requestDate: item.requestDate,
          requestNum: item.requestNum,
          totalPrice: fixPrice(item.totalPrice),
          totalFaxE: fixPrice(item.totalFaxE),
          totalFaxT: fixPrice(item.totalFaxT),
          body: tempBody,
          totalPriceE: fixPrice(item.totalPriceE),
          totalPriceT: fixPrice(item.totalPriceT),
          receviceDate: item.receviceDate,
          fare: fixPrice(item.fare),
          adjust: fixPrice(item.adjust),
          faxFlag: Number(item.faxFlag)
        },
        responseType: 'blob',
        headers: {
          token: store.state.userInfo.accessToken
        }
      })
          .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', item.requestNum + '.pdf');
            document.body.appendChild(link);
            link.click();
            window.URL.revokeObjectURL(url);
            link.remove();
          })
          .catch((error) => console.error('Download error:', error));
    }
  }
}
</script>

<style scoped>

</style>
