import imageCompression from "browser-image-compression";

/**
 * ファイル圧縮
 * @param {File} imageFile 画像ファイル
 * @returns 圧縮後のファイル
 */
export const compression = async imageFile => {
    const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1280
    };
    return await imageCompression(imageFile, options);
};

/**
 * プレビュー用の画像を取得する.
 * @param {File} imageFile 画像ファイル
 * @returns プレビュー用の画像URL
 */
export const getDataUrl = async imageFile => {
    return await imageCompression.getDataUrlFromFile(imageFile);
};