import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate';
import errorDialog from "@/store/errorDialog";
import userInfo from "@/store/userInfo";

Vue.use(Vuex)

const modules = {
    errorDialog,
    userInfo
}

const store = new Vuex.Store({
    state: {},
    modules: modules,
    plugins: [
        createPersistedState({
            key: "saveState",
            paths: [
                "errorDialog",
                "userInfo"
            ],
            storage: window.sessionStorage
        })
    ]
});

export default store;


