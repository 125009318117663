var render = function render(){var _vm=this,_c=_vm._self._c;return _c('with-side-menu',[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"justify":"start","align":"center","dense":""}},[_c('v-col',{attrs:{"cols":"2"}},[_c('h1',[_vm._v("請求出荷調整")])])],1),_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"3"}},[_vm._v(" 会社名:"+_vm._s(this.$route.params.item.companyName)+" ")]),_c('v-col',{attrs:{"cols":"3"}},[_vm._v(" 発注書番号:"+_vm._s(this.$route.params.item.requestNum)+" ")]),_c('v-col',{attrs:{"cols":"3"}},[_c('v-btn',{staticClass:"white--text button",staticStyle:{"width":"150px"},attrs:{"color":"mainColor","loading":_vm.searchFlag,"disabled":_vm.searchFlag},on:{"click":function($event){return _vm.updateStock()}}},[_vm._v("保存 ")])],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-btn',{staticClass:"white--text button",staticStyle:{"width":"250px"},attrs:{"color":"mainColor"},on:{"click":function($event){return _vm.changeNumber()}}},[_vm._v("全部出荷 ")])],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticStyle:{"width":"100%"},attrs:{"dense":"","headers":_vm.headers,"loading":this.isLoading,"items":_vm.items,"items-per-page":20,"item-key":"id","footer-props":{
    'items-per-page-options': [20, 50, 100],
    showFirstLastPage: true,
    firstIcon: 'mdi-arrow-collapse-left',
    lastIcon: 'mdi-arrow-collapse-right',
    prevIcon: 'mdi-arrow-left',
    nextIcon: 'mdi-arrow-right'
  }},scopedSlots:_vm._u([{key:"body",fn:function({ items }){return [_c('tbody',[(_vm.isLoading)?_c('tr',[_c('td')]):_vm._e(),_vm._l((items),function(item,index){return _c('tr',{key:index},[_c('td',[_vm._v(" "+_vm._s(item.jancode)+" ")]),_c('td',[_vm._v(" "+_vm._s(item.goodName)+" ")]),_c('td',[_vm._v(" "+_vm._s(item.number)+" ")]),_c('td',[_c('v-text-field',{attrs:{"outlined":"","dense":"","solo":"","flat":"","hide-details":""},model:{value:(item.transactionDate),callback:function ($$v) {_vm.$set(item, "transactionDate", $$v)},expression:"item.transactionDate"}})],1),_c('td',[_c('v-text-field',{attrs:{"outlined":"","dense":"","solo":"","flat":"","hide-details":""},model:{value:(item.inNumber),callback:function ($$v) {_vm.$set(item, "inNumber", $$v)},expression:"item.inNumber"}})],1)])})],2)]}}])})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }