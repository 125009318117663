<template>
  <with-side-menu>
    <v-container fluid>
      <v-row justify="start" align="center" dense>
        <v-col cols="2">
          <h1>調整</h1>
        </v-col>
      </v-row>
      <v-row align="center" justify="center">
        <v-col cols="12">
          商品名：{{ this.$route.params.goodName }}
        </v-col>
        <v-col cols="12">
          JANコード：{{ this.$route.params.jancode }}
        </v-col>
        <v-col cols="12">
          現在の在庫数：{{ stock }}
        </v-col>
        <v-col cols="3">
          <v-text-field label="調整数(総数)" v-model="inNumber" >

          </v-text-field>
        </v-col>
        <v-col cols="9">

        </v-col>
        <v-col cols="3">
          <v-text-field label="調整時間" v-model="transactionDate">

          </v-text-field>
        </v-col>
        <v-col cols="9">

        </v-col>
<!--        <v-col cols="12">-->
<!--          <v-btn-->
<!--              color="mainColor"-->
<!--              class="white&#45;&#45;text button"-->
<!--              style="width: 150px"-->
<!--              @click="zero()"-->
<!--          >在庫数0になる、自動計算-->
<!--          </v-btn>-->
<!--        </v-col>-->
        <v-col cols="12">
          <v-btn
              color="mainColor"
              class="white--text button"
              style="width: 150px"
              :loading="searchFlag"
              :disabled="searchFlag"
              @click="updateStock()"
          >保存
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </with-side-menu>
</template>

<script>
import WithSideMenu from "@/components/WithSideMenu";
import {searchStock, addStock} from "@/api/stock";
import store from "@/store";
import {checkJanCode} from "@/api/good";

export default {
  name: "AdminStockEdit",
  components: {
    WithSideMenu
  },
  data() {
    return {
      searchFlag: false,
      isLoading: false,
      items: [],
      inNumber: null,
      nowNumber: 0,
      stock: 0,
      id: null,
      transactionDate: `${new Date().getFullYear()}-${(new Date().getMonth() + 1).toString().padStart(2, '0')}-${new Date().getDate().toString().padStart(2, '0')}`
    }
  },
  async mounted() {
    this.isLoading = true;
    const response = await searchStock({
      jancode: this.$route.params.jancode,
      transactionType: "0"
    })
    const tempResult = response.data.result;
    if (tempResult.length === 0) {
      this.id = null
    } else {
      this.id = tempResult[0].id;
      this.nowNumber = tempResult[0].quantity;
    }
    const responseStockNumber = await checkJanCode({jancode: this.$route.params.jancode});
    this.stock = responseStockNumber.data.goodDetail.stockNumber;
    this.isLoading = false;
  },
  methods: {
    // zero() {
    //   this.inNumber = this.inNumber++
    // },
    async updateStock() {
      this.searchFlag = true;
      if(Number(this.inNumber) < 0){
        alert("在庫-数はできない");
        this.searchFlag = false;
        return
      }
      if (this.id == null) {
        await addStock({
          addFlag: true,
          jancode: this.$route.params.jancode,
          transactionType: 0,
          quantity: Number(this.inNumber),
          companyName: "admin",
          relativeNumber: "admin",
          priceIncludingTax: this.$route.params.priceExcludingTax.toString(),
          transactionDate: this.transactionDate,
          userName: store.state.userInfo.userName
        })
      } else {
        await addStock({
          addFlag: false,
          jancode: this.$route.params.jancode,
          id: this.id,
          transactionType: 0,
          quantity: Number(this.inNumber)-Number(this.stock)+Number(this.nowNumber),
          companyName: "admin",
          relativeNumber: "admin",
          priceIncludingTax: this.$route.params.priceExcludingTax.toString(),
          transactionDate: this.transactionDate,
          userName: store.state.userInfo.userName
        })
      }
      this.searchFlag = false;
      alert("更新成功しました。")
      await this.$router.push({name: "StockView"});
    }
  }
}
</script>

<style scoped>

</style>