<template>
  <v-app>
    <router-view/>
    <ErrorDialog></ErrorDialog>
  </v-app>
</template>

<script>
import ErrorDialog from "@/components/ErrorDialog.vue";

export default {
  name: 'App',
  components: {
    ErrorDialog
  },
  data: () => ({
    //
  }),
};
</script>
