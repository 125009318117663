<template>
  <with-side-menu>
    <v-container fluid>
      <v-row>
        <v-col cols="12">
          <v-row class="ml-3 mb-3" align="center">
            <v-col cols="3"><h1>PDF請求書修正</h1></v-col>
            <v-col cols="8"><h2>請求書番号：{{ requestNum }}</h2></v-col>
          </v-row>
          <v-row dense align="center">
            <v-col cols="12">
              <v-row align="center">
                <v-col cols="1">
                  <p class="web-title">請求対象先：</p>
                </v-col>
                <v-col cols="2">
                  <v-text-field disabled readonly v-model="companyName"
                  ></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                      label="請求日"
                      v-model="date"
                      hide-details
                      outlined
                      dense
                  ></v-text-field>
                </v-col>
                <v-col cols="1">
                  <v-menu
                      offset-y
                      v-model="menu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                  >
                    <template v-slot:activator="{ on, attrs}">
                      <v-icon large color="mainColor" v-bind="attrs" v-on="on">
                        mdi-calendar-month-outline
                      </v-icon>
                    </template>
                    <v-date-picker
                        v-model="date"
                        no-title
                        @input="menu = false"
                        locale="ja_jp"
                    >
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                      label="支払い期限日"
                      v-model="date1"
                      hide-details
                      outlined
                      dense
                  ></v-text-field>
                </v-col>
                <v-col cols="1">
                  <v-menu
                      offset-y
                      v-model="menu1"
                      :close-on-content-click="false"
                      transition="scale-transition"
                  >
                    <template v-slot:activator="{ on, attrs}">
                      <v-icon large color="mainColor" v-bind="attrs" v-on="on">
                        mdi-calendar-month-outline
                      </v-icon>
                    </template>
                    <v-date-picker
                        v-model="date1"
                        no-title
                        @input="menu1 = false"
                        locale="ja_jp"
                    >
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-row>
                  <v-col cols="2">
                    <v-radio-group v-model="faxFlag" row>
                      <v-radio label="税拔" :value="0"></v-radio>
                      <v-radio label="税入" :value="1"></v-radio>
                    </v-radio-group>
                  </v-col>
                  <v-col cols="2">
                    <v-text-field dense outlined v-model="fare" label="送料">

                    </v-text-field>
                  </v-col>
                  <v-col cols="2">
                    <v-text-field dense outlined v-model="adjust" label="調整金額">

                    </v-text-field>
                  </v-col>
                  <v-col cols="3">
                    <v-btn class=" white--text button" color="mainColor" @click="getDataForm"
                    >テーブルデータ表示(値段はリセットします)
                    </v-btn>
                  </v-col>
                  <v-col cols="2">
                    <v-btn :disabled="this.items.length<=0" width="160px" class=" white--text button" color="mainColor"
                           @click="updateData"
                    >更新
                    </v-btn>
                  </v-col>
                  <v-col cols="12">
                    <v-row dense>
                      <v-col cols="4">
                        <v-file-input
                            label="お客様発注書"
                            accept=".pdf"
                            v-model="pdfUpload"></v-file-input>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-row>
            </v-col>
            <v-col cols="4">
              <v-textarea outlined label="JANCODE入力" v-model="textBody">

              </v-textarea>
            </v-col>
            <v-col cols="2">
              <v-textarea outlined label="数量入力" v-model="numberTextBody">

              </v-textarea>
            </v-col>
            <v-col cols="4">
              <v-textarea outlined label="備考（PDF表示しない）" v-model="note">

              </v-textarea>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row align="center" justify="center" style="margin-top: 70px">
        <v-data-table
            style="width: 100%"
            dense
            :headers="headers"
            :loading="this.isLoading"
            :items="items"
            :items-per-page="20"
            item-key="id"
            :footer-props="{
      'items-per-page-options': [20, 50, 100],
      showFirstLastPage: true,
      firstIcon: 'mdi-arrow-collapse-left',
      lastIcon: 'mdi-arrow-collapse-right',
      prevIcon: 'mdi-arrow-left',
      nextIcon: 'mdi-arrow-right'
    }"
        >
          <template v-slot:body="{ items }">
            <tbody>
            <tr v-if="isLoading">
              <td></td>
            </tr>
            <tr
                v-for="(item,index) in items"
                :key="index"
            >
              <td>
                {{ item.jancode }}
              </td>
              <td>
                {{ item.goodName }}
              </td>
              <td>
                {{ item.faxRate + "%" }}
              </td>
              <td>
                <v-text-field
                    outlined
                    v-model="item.number"
                    dense
                    solo
                    flat
                    hide-details
                ></v-text-field>
              </td>
              <td>
                {{ item.string }}
              </td>
              <td>
                <v-text-field
                    outlined
                    v-model="item.price"
                    dense
                    solo
                    flat
                    hide-details
                ></v-text-field>
              </td>
              <td>
                {{ Number(item.number) * Number(item.price) }}
              </td>
              <td>
                <v-icon
                    @click="deleteItem(index)">
                  mdi-delete
                </v-icon>
              </td>
            </tr>
            </tbody>
          </template>
        </v-data-table>
      </v-row>
    </v-container>
  </with-side-menu>
</template>

<script>
import WithSideMenu from "@/components/WithSideMenu";
import {getGoodList} from "@/api/good";
import store from "@/store";
import {updatePDF} from "@/api/pdf";
import axios from "axios";
import {baseURL} from "@/api/RestClient";

export default {
  name: "PdfRequestEdit",
  components: {
    WithSideMenu
  },
  data() {
    return {
      headers: [
        {
          "text": "JANコード",
          "value": "jancode",
          "class": "tableHeader white--text",
        },
        {
          "text": "品名",
          "value": "goodName",
          "align": "start",
          "class": "tableHeader white--text",
          "width": "600"
        },
        {
          "text": "税率",
          "value": "faxRate",
          "align": "start",
          "class": "tableHeader white--text",
        },
        {
          "text": "数量",
          "value": "number",
          "class": "tableHeader white--text"
        },
        {
          "text": "単位",
          "value": "string",
          "class": "tableHeader white--text"
        },
        {
          "text": "単価",
          "value": "price",
          "class": "tableHeader white--text"
        },
        {
          "text": "金額",
          "value": "",
          "class": "tableHeader white--text"
        },
        {
          "text": "削除",
          "value": "",
          "class": "tableHeader white--text"
        }
      ],
      companyName: "",
      textBody: null,
      requestNum: "",
      items: [],
      numberTextBody: null,
      isLoading: false,
      menu: false,
      menu1: false,
      fare: 0,
      adjust: 0,
      faxFlag: 0,
      note: null,
      pdfUpload: null,
      date: `${new Date().getFullYear()}-${(new Date().getMonth() + 1).toString().padStart(2, '0')}-${new Date().getDate().toString().padStart(2, '0')}`,
      date1: `${new Date().getFullYear()}-${(new Date().getMonth() + 1).toString().padStart(2, '0')}-${new Date().getDate().toString().padStart(2, '0')}`
    }
  },
  methods: {
    deleteItem(index) {
      this.items.splice(index, 1);
    },
    async getDataForm() {
      this.items = [];
      const waitProList = this.textBody ? this.textBody.split('\n') : [];
      const numberText = this.numberTextBody ? this.numberTextBody.split('\n') : [];
      const flag = waitProList.length === numberText.length
      for (let i = 0; i < waitProList.length; i++) {
        if (waitProList[i] != null && waitProList[i] !== "") {
          let detail = await getGoodList({jancode: waitProList[i]})
          let tempData = detail.data.result[0]
          let jsonBody = {
            jancode: tempData.jancode,
            goodName: tempData.nameJp,
            faxRate: tempData.faxRate,
            number: flag ? numberText[i] : 1,
            string: '個',
            price: tempData.priceExcludingTax,
            pricePlus: Number(flag ? numberText[i] : 1) * Number(tempData.priceExcludingTax)
          }
          this.items.push(jsonBody)
        }
      }
    },
    async updateData() {


      let jsonBody = this.items.map(item => ({
        jancode: item.jancode,
        goodName: item.goodName,
        faxRate: item.faxRate,
        number: Number(item.number),
        string: item.string,
        price: Number(item.price),
        totalPrice: Number(item.number) * Number(item.price)
      }));
      let totalPricePlus = 0;
      let totalPriceE = 0;
      let totalPriceT = 0;
      let totalFaxE = 0;
      let totalFaxT = 0;
      if (Number(this.faxFlag) === 0) {
        for (let i = 0; i < this.items.length; i++) {
          if (Number(this.items[i].faxRate) === 10) {
            totalPriceT = totalPriceT + Number(this.items[i].number) * Number(this.items[i].price);
          } else {
            totalPriceE = totalPriceE + Number(this.items[i].number) * Number(this.items[i].price);
          }
          totalPricePlus = totalPricePlus + Number(this.items[i].number) * Number(this.items[i].price);
        }

        totalPriceT = totalPriceT + Number(this.fare) + Number(this.adjust);
        totalPricePlus = totalPricePlus+ Number(this.fare) + Number(this.adjust);
        totalFaxT = Math.round(Number(totalPriceT) * 0.1);
        totalFaxE = Math.round(Number(totalPriceE) * 0.08);
        totalPricePlus = totalPricePlus + totalFaxT + totalFaxE;
      } else {
        for (let i = 0; i < this.items.length; i++) {
          if (Number(this.items[i].faxRate) === 10) {
            totalPriceT = totalPriceT + Number(this.items[i].number) * Number(this.items[i].price);
          } else {
            totalPriceE = totalPriceE + Number(this.items[i].number) * Number(this.items[i].price);
          }
          totalPricePlus = totalPricePlus + Math.round(Number(this.items[i].number) * Number(this.items[i].price));
        }
        totalPriceT = totalPriceT + Number(this.fare) + Number(this.adjust);
        totalPricePlus = totalPricePlus+ Number(this.fare) + Number(this.adjust);
        totalFaxT = Math.round(Number(totalPriceT) / 1.1 * 0.1);
        totalFaxE = Math.round(Number(totalPriceE) / 1.08 * 0.08);
      }

      let pdfPath = null;

      if (this.pdfUpload != null) {
        try {
          const file = this.pdfUpload;
          let formData = new FormData();
          formData.append('file', file);
          const headers = {
            'token': store.state.userInfo.accessToken,
          };
          const res = await axios.post(baseURL + "author/ImageUpload", formData, {headers})
          pdfPath = res.data;
        } catch (error) {
          console.log(error)
        }
      }


      try {
        await updatePDF({
          requestDate: this.date,
          limitDate: this.date1,
          requestNum: this.requestNum,
          totalPrice: totalPricePlus.toString(),
          requestBody: JSON.stringify(jsonBody),
          totalFaxE: totalFaxE.toString(),
          totalPriceE: totalPriceE.toString(),
          totalFaxT: totalFaxT.toString(),
          totalPriceT: totalPriceT.toString(),
          fare: this.fare.toString(),
          adjust: this.adjust.toString(),
          faxFlag: Number(this.faxFlag),
          note: this.note,
          pdfPath: pdfPath,
          updateName: store.state.userInfo.userName
        });
      } catch (e) {
        console.log(e)
        alert("請求書新規失敗しました。")
      }
      alert("請求書更新成功しました。番号：" + this.requestNum);
      await this.$router.push({name: "RequestView"});
    }
  },
  async mounted() {
    const item = this.$route.params.item;
    this.companyName = item.companyName;
    this.requestNum = item.requestNum;
    this.date = item.requestDate;
    this.date1 = item.limitDate;
    this.fare = item.fare;
    this.adjust = item.adjust;
    this.note = item.note;
    this.items = JSON.parse(item.requestBody);
    this.faxFlag = Number(item.faxFlag);
    let textBodyString = "";
    let textBodyNumber = "";
    for (let i = 0; i < this.items.length; i++) {
      textBodyString = textBodyString + this.items[i].jancode + "\n";
      textBodyNumber = textBodyNumber + this.items[i].number + "\n";
    }
    this.textBody = textBodyString;
    this.numberTextBody = textBodyNumber;
  }
}
</script>

<style scoped>
.web-title {
  font-size: 18px;
}
</style>
