<template>
  <v-data-table
      style="width: 100%"
      dense
      :headers="headers"
      :loading="this.isLoading"
      :items="items"
      :items-per-page="20"
      item-key="id"
      :footer-props="{
      'items-per-page-options': [20, 50, 100],
      showFirstLastPage: true,
      firstIcon: 'mdi-arrow-collapse-left',
      lastIcon: 'mdi-arrow-collapse-right',
      prevIcon: 'mdi-arrow-left',
      nextIcon: 'mdi-arrow-right'
    }"
  >
    <template v-slot:body="{ items }">
      <tbody>
      <tr v-if="isLoading">
        <td></td>
      </tr>
      <tr
          v-for="item in items"
          :key="item.id"
      >
        <td>{{ item.requestDate }}</td>
        <td>{{ item.requestNum }}</td>
        <td>{{ item.companyName }}</td>
        <td>{{ fixPrice(item.totalPrice) }}</td>
        <td>{{ item.createUser }}</td>
        <td>{{ item.note }}</td>
        <td>{{ item.limitDate }}</td>
        <td>
          <v-icon
              @click="preRev(item)"
          >
            mdi-file-find
          </v-icon>
          <v-icon
              class="pl-2"
              @click="edit(item)"
          >
            mdi-pencil
          </v-icon>
          <v-icon
              class="pl-2"
              @click="downloadPDF(item)"
          >
            mdi-arrow-down-bold
          </v-icon>
          <v-icon
              class="pl-2"
              @click="copy(item)"
          >
            mdi-content-copy
          </v-icon>
        </td>
        <td :style="{ color: getStockStatusColor(item.stockStatus) }">
          <div style="cursor: pointer" @click="editStock(item)"> {{ showStockStatusStringR(item.stockStatus) }}</div>
        </td>
        <td :style="{ color: getStockStatusColor(item.payStatus) }">
          <div style="cursor: pointer" @click="editPrice(item)">{{ showPayStatusString(item.payStatus) }}</div>
        </td>
        <td>
          <v-icon
              v-if="item.pdfPath != null && item.pdfPath !== '0' && item.pdfPath !== ''"
              class="pl-2"
              @click="openPdf(item.pdfPath)"
          >
            mdi-file-document-plus-outline
          </v-icon>
        </td>

        <td>
          <v-icon
              class="pl-2"
              @click="deleteItem(item.requestNum)"
          >
            mdi-delete
          </v-icon>
        </td>
      </tr>
      </tbody>
    </template>
  </v-data-table>
</template>

<script>
import {fixPrice, showPayStatusString, showStockStatusStringR, getStockStatusColor} from "@/utils/common";
import axios from "axios";
import {baseURL} from "@/api/RestClient";
import store from "@/store";
import {deleteRequest} from "@/api/pdf";


export default {
  name: "CompanyTable",
  props: {
    items: Array,
    isLoading: Boolean
  },
  data() {
    return {
      headers: [
        {
          "text": "請求日",
          "value": "requestDate",
          "class": "tableHeader white--text",
          "width": "80px"
        },
        {
          "text": "請求書番号",
          "value": "requestNum",
          "class": "tableHeader white--text",
          "width": "80px"
        },
        {
          "text": "請求書宛先会社名",
          "value": "companyName",
          "class": "tableHeader white--text",
          "width": "120px"
        },
        {
          "text": "請求金額",
          "value": "",
          "class": "tableHeader white--text",
          "width": "80px"
        },
        {
          "text": "作成者",
          "value": "",
          "class": "tableHeader white--text",
          "width": "100px"
        },
        {
          "text": "メモ",
          "value": "",
          "class": "tableHeader white--text",
          "width": "180px"
        },
        {
          "text": "振込期限",
          "value": "",
          "class": "tableHeader white--text",
          "width": "80px"
        },
        {
          "text": "PDF操作",
          "value": "",
          "class": "tableHeader white--text",
          "width": "150px"
        },
        {
          "text": "出庫ステータス",
          "value": "stockStatus",
          "class": "tableHeader white--text",
          "width": "100px"
        },
        {
          "text": "入金ステータス",
          "value": "payStatus",
          "class": "tableHeader white--text",
          "width": "100px"
        },
        {
          "text": "関連PDFファイル",
          "value": "pdfPath",
          "class": "tableHeader white--text",
          "width": "50px"
        },
        {
          "text": "削除",
          "value": "",
          "class": "tableHeader white--text",
          "width": "80px"
        }
      ],
    }
  },
  methods: {
    fixPrice, showPayStatusString, showStockStatusStringR, getStockStatusColor,
    async editPrice(item) {
      await this.$router.push({
        name: 'EditMoneyRequest',
        params: {
          item: item
        }
      })
    },
    async openPdf(path) {
      try {
        const pdfPath = path; // Replace with the actual file path
        const response = await axios.post(baseURL + "author/pdf/open", {pdfPath}, {
          headers: {token: store.state.userInfo.accessToken},
          responseType: 'blob' // Important: Set the responseType to 'blob' to handle binary data
        });

        const blob = new Blob([response.data], {type: 'application/pdf'});
        const url = window.URL.createObjectURL(blob);

        // Open the PDF file in a new tab
        window.open(url, '_blank');
      } catch (error) {
        console.error('Error fetching PDF:', error);
      }
    },
    async copy(item) {
      await this.$router.push({
        name: 'pdfRequestAdd',
        params: {
          item: item
        }
      })
    },
    async editStock(item) {
      await this.$router.push({
        name: 'EditStockRequest',
        params: {
          item: item
        }
      })
    },
    async editItem(id) {
      await this.$router.push({
        name: 'update-company',
        params: {
          id: id
        }
      })
    },
    async deleteItem(requestNum) {
      if (confirm("この請求書を削除してよろしでしょうか？")) {
        try {
          await deleteRequest({
            requestNum: requestNum
          });
        } catch (e) {
          alert("データ取得失敗しました。")
        }
        alert("削除成功しました。")
        location.reload();
      }
    },

    async edit(item) {
      await this.$router.push({
        name: 'PdfRequestEdit',
        params: {
          item: item
        }
      })
    },
    async preRev(item) {
      const jsonBody = JSON.parse(item.requestBody);
      let tempBody = jsonBody.map(item => [
        item.jancode,
        item.goodName,
        item.faxRate + "%",
        item.number.toString(),
        item.string,
        fixPrice(item.price),
        fixPrice(item.totalPrice),
      ]);
      axios({
        method: 'post',
        url: baseURL + 'author/pdf/request',
        data: {
          companyName: item.companyName,
          zip: item.zip,
          companyAddress: item.companyAddress,
          phone: item.phone,
          requestDate: item.requestDate,
          requestNum: item.requestNum,
          totalPrice: fixPrice(item.totalPrice),
          totalFaxE: fixPrice(item.totalFaxE),
          totalFaxT: fixPrice(item.totalFaxT),
          body: tempBody,
          totalPriceE: fixPrice(item.totalPriceE),
          totalPriceT: fixPrice(item.totalPriceT),
          limitDate: item.limitDate,
          fare: fixPrice(item.fare),
          adjust: fixPrice(item.adjust),
          faxFlag: Number(item.faxFlag)
        },
        responseType: 'blob',
        headers: {
          token: store.state.userInfo.accessToken
        }
      })
          .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data], {type: 'application/pdf'}));
            window.open(url, '_blank');
            window.URL.revokeObjectURL(url);
          })
          .catch((error) => console.error('Download error:', error));
    },
    async downloadPDF(item) {
      const jsonBody = JSON.parse(item.requestBody);
      let tempBody = jsonBody.map(item => [
        item.jancode,
        item.goodName,
        item.faxRate + "%",
        item.number.toString(),
        item.string,
        fixPrice(item.price),
        fixPrice(item.totalPrice),
      ]);
      axios({
        method: 'post',
        url: baseURL + 'author/pdf/request',
        data: {
          companyName: item.companyName,
          zip: item.zip,
          companyAddress: item.companyAddress,
          phone: item.phone,
          requestDate: item.requestDate,
          requestNum: item.requestNum,
          totalPrice: fixPrice(item.totalPrice),
          totalFaxE: fixPrice(item.totalFaxE),
          totalFaxT: fixPrice(item.totalFaxT),
          body: tempBody,
          totalPriceE: fixPrice(item.totalPriceE),
          totalPriceT: fixPrice(item.totalPriceT),
          limitDate: item.limitDate,
          fare: fixPrice(item.fare),
          adjust: fixPrice(item.adjust),
          faxFlag: Number(item.faxFlag)
        },
        responseType: 'blob',
        headers: {
          token: store.state.userInfo.accessToken
        }
      })
          .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', item.requestNum + '.pdf');
            document.body.appendChild(link);
            link.click();
            window.URL.revokeObjectURL(url);
            link.remove();
          })
          .catch((error) => console.error('Download error:', error));
    }
  }
}
</script>

<style scoped>

</style>
