<template>
  <v-main>
    <v-row justify="center" class="content_position">
      <v-col cols="12">
        <v-card
            elevation="6"
            class="mx-auto rounded-xl"
            width="550"
            height="500"
            outlined
            style="background-color: #00BFFF"
        >
          <v-card-title class="layout justify-center card-title">
            <h2>五洲在庫管理</h2>
          </v-card-title>
          <v-row justify="center">
            <v-col cols="10">
              <hr style="color: black;"/>
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col cols="9" class="text-field-group">
              <label for="idInput" class="text-field-label">
                ログインID
              </label>
              <v-text-field
                  hide-details="auto"
                  v-model="loginId"
                  id="idInput"
                  solo
                  dense
                  :error-messages="loginIdError"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col cols="9" class="text-field-group">
              <label for="passInput" class="text-field-label">パスワード</label>
              <v-text-field
                  :type="show1 ? 'text' : 'password'"
                  hide-details="auto"
                  :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                  v-model="password"
                  @click:append="show1 = !show1"
                  id="passInput"
                  solo
                  dense
                  :error-messages="passwordError"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col cols="4" class="text-field-group">
              <v-btn
                  color="#87CEFA"
                  class="v-btn--block white--text button"
                  large
                  :loading="loading"
                  :disabled="loading"
                  @click="login()"
              >ログイン
              </v-btn>
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col cols="8" class="text-field-group" style="text-align: center;">
              <div>GoShu22 © 2023 All rights reserved.</div>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-main>
</template>

<script>

import {loginPro} from "@/api/login";
import {isEmpty} from "@/utils/common";
import messages from "@/utils/messages";

export default {
  name: "login-page",
  data: () => ({
    loginId: "",
    password: "",
    loading: false,
    show1: false,
    loginIdError: "",
    passwordError: ""
  }),
  methods: {
    async login() {
      const fieldsToValidate = [
        { value: this.loginId, errorProperty: "loginIdError", errorMessage: messages.loginIdError },
        { value: this.password, errorProperty: "passwordError", errorMessage: messages.passwordError }
      ];

      for (const field of fieldsToValidate) {
        if (isEmpty(field.value)) {
          this[field.errorProperty] = field.errorMessage;
          return;
        } else {
          this[field.errorProperty] = "";
        }
      }

      this.loading = true;
      const loginResult = await loginPro({
        loginId: this.loginId,
        password: this.password
      })
      if (loginResult) {
        await this.$router.push({
          name: 'top-page'
        });
      }
      this.loading = false;
    }
  }

};
</script>
<style scoped>
.content_position {
  padding-top: 15vh;
}

.card-title {
  padding-top: 4vh;
  padding-bottom: 2vh;
  font-weight: bold;
}

.text-field-group {
  padding-top: 3vh;
}

.text-field-label {
  font-weight: bold;
}
</style>
