import { render, staticRenderFns } from "./ImportGoodCSV.vue?vue&type=template&id=bbb778d2&scoped=true&"
import script from "./ImportGoodCSV.vue?vue&type=script&lang=js&"
export * from "./ImportGoodCSV.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bbb778d2",
  null
  
)

export default component.exports