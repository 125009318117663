import { render, staticRenderFns } from "./EditStock.vue?vue&type=template&id=7ca4b047&scoped=true&"
import script from "./EditStock.vue?vue&type=script&lang=js&"
export * from "./EditStock.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7ca4b047",
  null
  
)

export default component.exports