<template>
  <with-side-menu>
    <v-container fluid>
      <v-row justify="start" align="center" dense>
        <v-col cols="2">
          <h1>請求書一覧</h1>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="3">
          <v-text-field dense
                        label="請求書宛先会社名"
                        v-model="companyName"
                        hide-details
                        outlined
                        @keyup.enter="searchRequest"
          ></v-text-field>
        </v-col>
        <v-col cols="3">
          <v-text-field dense
                        label="請求番号"
                        v-model="requestNum"
                        hide-details
                        outlined
                        @keyup.enter="searchRequest"
          ></v-text-field>
        </v-col>
        <v-col cols="2">
          <v-text-field dense
                        label="作成日付(検索開始日)"
                        v-model="requestDate"
                        hide-details
                        outlined
                        @keyup.enter="searchRequest"
          ></v-text-field>
        </v-col>
        <v-col cols="1">
          <v-menu
              offset-y
              v-model="menu"
              :close-on-content-click="false"
              transition="scale-transition"
          >
            <template v-slot:activator="{ on, attrs}">
              <v-icon large color="mainColor" v-bind="attrs" v-on="on">
                mdi-calendar-month-outline
              </v-icon>
            </template>
            <v-date-picker
                v-model="requestDate"
                no-title
                @input="menu = false"
                locale="ja_jp"
            >
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="2">
          <v-text-field dense
                        label="作成日付(検索終了日)"
                        v-model="requestEndDate"
                        hide-details
                        outlined
                        @keyup.enter="searchRequest"
          ></v-text-field>
        </v-col>
        <v-col cols="1">
          <v-menu
              offset-y
              v-model="menu1"
              :close-on-content-click="false"
              transition="scale-transition"
          >
            <template v-slot:activator="{ on, attrs}">
              <v-icon large color="mainColor" v-bind="attrs" v-on="on">
                mdi-calendar-month-outline
              </v-icon>
            </template>
            <v-date-picker
                v-model="requestEndDate"
                no-title
                @input="menu1 = false"
                locale="ja_jp"
            >
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="3">
          <v-text-field dense
                        label="Jancode"
                        v-model="jancode"
                        hide-details
                        outlined
                        @keyup.enter="searchRequest"
          ></v-text-field>
        </v-col>
        <v-col cols="3">
          <v-text-field dense
                        label="金額"
                        v-model="money"
                        hide-details
                        outlined
                        @keyup.enter="searchRequest"
          ></v-text-field>
        </v-col>
        <v-col cols="3">
          <v-btn
              color="mainColor"
              class="white--text button"
              style="width: 150px"
              :loading="searchFlag"
              :disabled="searchFlag"
              @click="searchRequest()"
          >検索
          </v-btn>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="2">
          <v-btn
              color="red"
              class="white--text button"
              style="width: 150px"
              @click="clear()"
          >クリア
          </v-btn>
        </v-col>
      </v-row>
      <v-row align="center" justify="center" style="margin-top: 70px">
        <RequestTable :items="searchDate"></RequestTable>
      </v-row>
    </v-container>
  </with-side-menu>
</template>

<script>
import WithSideMenu from "@/components/WithSideMenu";
import {searchRequest} from "@/api/pdf";
import RequestTable from "@/components/table/RequestTable.vue";
import {isEmpty} from "@/utils/common";
import {getReqCondition, setReqCondition} from "@/utils/storage";


export default {
  name: "RequestView",
  components: {
    WithSideMenu,
    RequestTable
  },
  data() {
    return {
      companyName: "",
      requestNum: "",
      menu: false,
      menu1: false,
      jancode: "",
      requestDate: null,
      requestEndDate: null,
      searchFlag: false,
      searchDate: [],
      money: null
    }
  },
  async mounted() {
    let result = null;
    try {
      let tempSave = getReqCondition();
      if (tempSave != null) {
        let jsonResult = JSON.parse(tempSave);
        this.companyName = jsonResult.companyName;
        this.requestNum = jsonResult.requestNum;
        this.createDate = jsonResult.createDate;
        this.requestEndDate = jsonResult.requestEndDate;
        this.jancode = jsonResult.jancode;
        this.money = jsonResult.money;
        result = await searchRequest(jsonResult);
      } else {
        result = await searchRequest({});
      }
      this.searchDate = result.data.result;
    } catch (e) {
      alert("初期データ取得失敗しました。")
    }
  },
  methods: {
    clear() {
      this.companyName = "";
      this.requestNum = "";
      this.jancode = "";
      this.requestDate = null;
      this.requestEndDate = null;
      this.money = null;
    },
    async searchRequest() {
      this.searchFlag = true;
      let payLoad = {
        companyName: isEmpty(this.companyName) ? null : this.companyName,
        requestNum: isEmpty(this.requestNum) ? null : this.requestNum,
        createDate: isEmpty(this.requestDate) ? null : this.requestDate,
        requestEndDate: isEmpty(this.requestEndDate) ? null : this.requestEndDate,
        jancode: isEmpty(this.jancode) ? null : this.jancode,
        money: isEmpty(this.money) ? null : this.money
      }

      setReqCondition(JSON.stringify(payLoad));

      try {
        let result = await searchRequest(payLoad);
        this.searchDate = result.data.result;
      } catch (e) {
        alert("データ取得失敗しました。")
      }
      this.searchFlag = false;
    }
  }
}
</script>

<style scoped>

</style>
