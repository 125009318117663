import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                // base: "#bf0042",
                secondary: "#fff0f5",
                anchor: "#8c9eff",
                tableHeader: "#333c5e", // TODO::accentColorに統一
                accent: "#3B3948", // TODO::defaultFontColorに統一
                chatPink: "#fdeaf0", // TODO::chatBackGroundColorに統一
                // XDに沿ったカラー表
                baseColor: "#fbf7f4",
                mainColor: "#00BFFF",
                accentColor: "#333c5e",
                chatBackGroundColor: "#fdeaf0",
                warningColor: "#ff0000",
                defaultFontColor: "#3b3948",
                linkFontColor: "#bf0042",
                grayFontColor: "#929292",
                progressColor: "#FBF7F4"
            }
        }
    }

});
