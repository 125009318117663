import {postWithToken} from "@/api/RestClient";
import {showDialog} from "@/utils/dialog";

export const getMoneyList = async payload => {
    try {
        return postWithToken("author/money/list", payload);
    } catch (error) {
        await showDialog("", error.response.data.message, "")
        throw error;
    }
};

export const getMoneyListRequest = async payload => {
    try {
        return postWithToken("author/money/moneyList", payload);
    } catch (error) {
        await showDialog("", error.response.data.message, "")
        throw error;
    }
};

export const addMoney = async payload => {
    try {
        return postWithToken("author/money/insert", payload);
    } catch (error) {
        await showDialog("", error.response.data.message, "")
        throw error;
    }
};

export const updateMoney = async payload => {
    try {
        return postWithToken("author/money/update", payload);
    } catch (error) {
        await showDialog("", error.response.data.message, "")
        throw error;
    }
};

