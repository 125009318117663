const namespaced = true;

const defaultData = {
    accessToken: "",
    author: 1,
    userName: ""
}

const getState = () => ({
    ...defaultData
});

export default {
    namespaced,
    state: getState(),
    mutations: {
        setUserInfo(state, payload) {
            state.accessToken = payload.accessToken;
            state.author = payload.author;
            state.userName = payload.userName;
        },
        init(state) {
            defaultData.title = "";
            Object.assign(state, defaultData)
        }
    },
    getters: {
        accessToken: state => state.accessToken,
        author: state => state.author,
        userName: state => state.userName
    },
    actions: {
        setUserInfo({commit}, {accessToken, author, userName}) {
            commit("setUserInfo", {accessToken, author, userName})
        },
        refresh({commit}) {
            commit("init");
        }
    }


}