import {postWithToken} from "@/api/RestClient";
import {showDialog} from "@/utils/dialog";


export const checkJanCode = async payload => {
    try {
        return postWithToken("author/good/janCheck", payload);
    } catch (error) {
        await showDialog("", error.response.data.message, "")
        throw error;
    }
};

export const insertGood = async payload => {
    try {
        return postWithToken("author/good/insert", payload);
    } catch (error) {
        await showDialog("", error.response.data.message, "")
        throw error;
    }
};

export const deleteGood = async payload => {
    try {
        return postWithToken("author/good/delete", payload);
    } catch (error) {
        await showDialog("", error.response.data.message, "")
        throw error;
    }
};

export const getGoodList = async payload => {
    try {
        return await postWithToken("author/good/goodList", payload);
    } catch (error) {
        await showDialog("", error.response.data.message, "")
        throw error;
    }
};

export const getGoodDetail = async payload => {
    try {
        return await postWithToken("author/good/goodDetail", payload);
    } catch (error) {
        await showDialog("", error.response.data.message, "")
        throw error;
    }
};

export const updateGood = async payload => {
    try {
        return await postWithToken("author/good/updateGood", payload);
    } catch (error) {
        await showDialog("", error.response.data.message, "")
        throw error;
    }
};