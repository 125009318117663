import {post} from "@/api/RestClient";
import {showDialog} from "@/utils/dialog";
import store from "@/store";

export const loginPro = async payload => {
    try {
        const response = await post("private/login", payload);
        const adminUserInfo = response.data.AdminUserInfo
        if (adminUserInfo) {
            await store.dispatch("userInfo/setUserInfo", {
                accessToken: adminUserInfo.accessToken,
                author: adminUserInfo.author,
                userName: adminUserInfo.userName
            });
        }
        return true;
    } catch (error) {
        await showDialog("", error.response.data.message, "")
        return false;
    }
};