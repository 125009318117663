<template>
  <with-side-menu>
    <v-container fluid>
      <v-row>
        <v-col cols="12">
          <v-row class="ml-3 mb-3">
            <h1>入金操作</h1>
          </v-row>
          <v-row dense>
            <v-col cols="2"></v-col>
            <v-col cols="2">
              <p class="web-title">請求書番号</p>
            </v-col>
            <v-col cols="4">
              <v-text-field dense outlined v-model="relativeNumber" :disabled="true">
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="2"></v-col>
            <v-col cols="2">
              <p class="web-title">メモ</p>
            </v-col>
            <v-col cols="4">
              <v-text-field dense outlined v-model="note">
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="2"></v-col>
            <v-col cols="2">
              <p class="web-title">入金総額</p>
            </v-col>
            <v-col cols="4">
              <v-text-field dense outlined v-model="targetMoney" :disabled="true">
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense v-for="(item, index) in payInfo" :key="index">
            <v-col cols="2"></v-col>
            <v-col cols="1">
              <p class="web-title">入金方法 </p>
            </v-col>
            <v-col cols="2">
              <v-select dense outlined v-model="item.method" :items="['銀行振込','現金']">
              </v-select>
            </v-col>
            <v-col cols="1">
              <p class="web-title">入金時間 </p>
            </v-col>
            <v-col cols="2">
              <v-text-field dense outlined v-model="item.time">
              </v-text-field>
            </v-col>
            <v-col cols="1">
              <p class="web-title">入金額</p>
            </v-col>
            <v-col cols="2">
              <v-text-field dense outlined v-model="item.money">
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="2" class="text-center">
            </v-col>
            <v-col cols="3">
              <v-btn width="120px" class=" white--text button" color="mainColor" @click="addRecord()">記録追加</v-btn>
            </v-col>
            <v-col cols="3">
              <v-btn width="120px" class=" white--text button" color="mainColor" @click="deleteRecord()">記録削除
              </v-btn>
            </v-col>
            <v-col cols="3">
              <v-btn width="220px" class=" white--text button" color="mainColor" @click="changeMoney()">完了金額自動入力
              </v-btn>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col cols="12" class="text-center">
              <v-btn width="120px" class=" white--text button" color="mainColor" @click="addMoney()">更新</v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </with-side-menu>
</template>

<script>
import WithSideMenu from "@/components/WithSideMenu";
import {getMoneyList, addMoney, updateMoney} from "@/api/money";
import store from "@/store";

export default {
  name: "EditMoneyRequest",
  components: {
    WithSideMenu
  },
  data() {
    return {
      relativeNumber: null,
      menu1: false,
      note: null,
      payInfo: [],
      targetMoney: 0,
      companyName: null,
      id: null
    }
  },
  async mounted() {
    const requestBody = this.$route.params.item;
    this.targetMoney = requestBody.totalPrice;
    this.relativeNumber = requestBody.requestNum;
    this.companyName = requestBody.companyName;
    const response = await getMoneyList({relativeNumber: this.relativeNumber})
    if (response.data.result.length > 0) {
      this.id = response.data.result[0].id;
      this.note = response.data.result[0].note;
      this.payInfo = JSON.parse(response.data.result[0].payInfo);
    }
  },
  methods: {
    addRecord() {
      this.payInfo.push({
        money: 0,
        time: `${new Date().getFullYear()}-${(new Date().getMonth() + 1).toString().padStart(2, '0')}-${new Date().getDate().toString().padStart(2, '0')}`,
        method: "銀行振込"
      })
    },
    deleteRecord() {
      this.payInfo.pop();
    },
    changeMoney() {
      let totalMoney = this.payInfo.reduce((total, currentItem) => {
        return total + Number(currentItem.money);
      }, 0);
      this.payInfo.push({
        money: Number(this.targetMoney) - totalMoney,
        time: `${new Date().getFullYear()}-${(new Date().getMonth() + 1).toString().padStart(2, '0')}-${new Date().getDate().toString().padStart(2, '0')}`,
        method: "銀行振込"
      })
    },
    async addMoney() {
      this.loading = true;
      let totalMoney = this.payInfo.reduce((total, currentItem) => {
        return total + Number(currentItem.money);
      }, 0);
      if (totalMoney > Number(this.targetMoney)) {
        alert("金額入金間違いました。再度入力してください。")
        return;
      }
      let payStatus = 0;
      if (totalMoney > 0 && totalMoney < Number(this.targetMoney)) {
        payStatus = 1;
      } else if (totalMoney === Number(this.targetMoney)) {
        payStatus = 2;
      }
      if (this.id == null) {
        try {
          await addMoney({
            relativeNumber: this.relativeNumber,
            payStatus: payStatus.toString(),
            companyName: this.companyName,
            note: this.note,
            type: 1,
            payInfo: JSON.stringify(this.payInfo),
            goalMoney: this.targetMoney,
            createName: store.state.userInfo.userName
          })
        } catch (e) {
          console.log(e)
          alert("入金更新失敗しました。");
        }
      } else {
        try {
          await updateMoney({
            id: Number(this.id),
            relativeNumber: this.relativeNumber,
            payStatus: payStatus.toString(),
            companyName: this.companyName,
            note: this.note,
            type: 1,
            payInfo: JSON.stringify(this.payInfo),
            goalMoney: this.targetMoney,
            createName: store.state.userInfo.userName
          })
        } catch (e) {
          console.log(e)
          alert("入金更新失敗しました。");
        }
      }
      alert("入金更新成功しました。");
      await this.$router.push({name: 'RequestView'})
      this.loading = false;
    }
  }
}
</script>

<style scoped>
.web-title {
  font-size: 18px;
}

.red-asterisk {
  color: red;
}
</style>
