<template>
  <with-side-menu>
    <v-container fluid>
      <v-row justify="start" align="center" dense>
        <v-col cols="2">
          <h1>商品一覧</h1>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="3">
          <v-text-field dense
                        label="JANコード"
                        v-model="janCode"
                        hide-details
                        outlined
                        @keyup.enter="searchStocks"
          ></v-text-field>
        </v-col>
        <v-col cols="3">
          <v-text-field dense
                        label="商品名(日中英)"
                        v-model="goodName"
                        hide-details
                        outlined
                        @keyup.enter="searchStocks"
          ></v-text-field>
        </v-col>
        <v-col cols="3">
          <v-text-field dense
                        label="カテゴリ"
                        v-model="categoryName"
                        hide-details
                        outlined
                        @keyup.enter="searchStocks"
          ></v-text-field>
        </v-col>
        <v-col cols="3">
          <v-text-field dense
                        label="ブランド"
                        v-model="brandName"
                        hide-details
                        outlined
                        @keyup.enter="searchStocks"
          ></v-text-field>
        </v-col>
        <v-col cols="3">
          <v-text-field dense
                        label="会社名"
                        v-model="companyName"
                        hide-details
                        outlined
                        @keyup.enter="searchStocks"
          ></v-text-field>
        </v-col>
        <v-col cols="3">
          <v-btn
              color="mainColor"
              class="white--text button"
              style="width: 150px"
              :loading="searchFlag"
              :disabled="searchFlag"
              @click="searchStocks()"
          >検索
          </v-btn>
        </v-col>
        <v-col cols="3">
          <v-btn
              color="orange"
              class="white--text button"
              :loading="searchFlag"
              :disabled="searchFlag"
              @click="downloadCSV"
          >検索結果CSVファイルダウンロード
          </v-btn>
        </v-col>
        <v-col cols="3">
          <v-btn
              color="red"
              class="white--text button"
              :loading="searchFlag"
              :disabled="searchFlag"
              @click="deleteAll"
          >検索結果全部削除
          </v-btn>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="2">
          <v-btn
              color="red"
              class="white--text button"
              style="width: 150px"
              @click="clear()"
          >クリア
          </v-btn>
        </v-col>
      </v-row>
      <v-row align="center" justify="center" style="margin-top: 70px">
        <StockTable :items="searchDate"></StockTable>
      </v-row>
    </v-container>
  </with-side-menu>
</template>

<script>
import WithSideMenu from "@/components/WithSideMenu";
import {deleteGood, getGoodList} from "@/api/good";
import StockTable from "@/components/table/StockTable.vue";
import {isEmpty} from "@/utils/common";
import Papa from 'papaparse';


export default {
  name: "GoodView",
  components: {
    WithSideMenu,
    StockTable
  },
  data() {
    return {
      goodName: "",
      janCode: "",
      categoryName: "",
      brandName: "",
      companyName: "",
      searchFlag: false,
      searchDate: []
    }
  },
  async mounted() {
    try {
      let result = await getGoodList({});
      this.searchDate = result.data.result;
    } catch (e) {
      alert("初期データ取得失敗しました。")
    }
  },
  methods: {
    clear() {
      this.goodName = "";
      this.janCode = "";
      this.categoryName = "";
      this.brandName = "";
      this.companyName = "";
    },
    async deleteAll() {
      this.searchFlag = true;
      await this.searchStocks();
      const confirmDelete = window.confirm("★現在検索結果全部削除するので（戻すできない）、確認の上で削除してください。");
      if (confirmDelete) {
        for (let i = 0; i < this.searchDate.length; i++) {
          try {
            await deleteGood({id: this.searchDate[i].id.toString()})
          } catch (e) {
            alert("データ削除エラー発生しました")
            this.searchFlag = false;
            return;
          }
        }
      }
      alert("データ削除成功しました。")
      this.searchFlag = false;
      location.reload();
    },

    async downloadCSV() {
      this.searchFlag = true;
      await this.searchStocks();
      const headers = [{text: 'JANコード', value: 'jancode'},
        {text: 'カテゴリ', value: 'categoryName'},
        {text: 'ブランド', value: 'brandName'},
        {text: '商品名(日文)', value: 'nameJp'},
        {text: '商品名(英文)', value: 'nameEn'},
        {text: '商品名(中文)', value: 'nameCn'},
        {text: '商品画像', value: 'pic'},
        {text: 'ケース_入り数', value: 'caseQuantity'},
        {text: '定価（税抜）', value: 'priceExcludingTax'},
        {text: '定価（税込）', value: 'priceIncludingTax'},
        {text: '税率', value: 'faxRate'},
        {text: '割引', value: 'discount'},
        {text: '商品説明', value: 'info'}];

      let maxTransportDetails = 0;
      this.searchDate.forEach(item => {
        const jsonBody = JSON.parse(item['tansportDetail']);
        if (jsonBody.length > maxTransportDetails) {
          maxTransportDetails = jsonBody.length;
        }
      });

      for (let i = 0; i < maxTransportDetails; i++) {
        headers.push({text: `仕入会社名${i + 1}`, value: `tanSportCompanyName${i + 1}`});
        headers.push({text: `仕入価格${i + 1}`, value: `tanSportPrice${i + 1}`});
      }

      const csv = Papa.unparse({
        fields: headers.map(header => header.text),
        data: this.searchDate.map(item => {
          const rowData = [];
          headers.forEach(header => {
            if (header.value.startsWith('tanSportCompanyName')) {
              const index = parseInt(header.value.replace('tanSportCompanyName', ''));
              const jsonBody = JSON.parse(item['tansportDetail']);
              if (jsonBody[index - 1]) {
                rowData.push(jsonBody[index - 1].tanSportCompanyName);
              } else {
                rowData.push('');
              }
            } else if (header.value.startsWith('tanSportPrice')) {
              const index = parseInt(header.value.replace('tanSportPrice', ''));
              const jsonBody = JSON.parse(item['tansportDetail']);
              if (jsonBody[index - 1]) {
                rowData.push(jsonBody[index - 1].tanSportPrice);
              } else {
                rowData.push('');
              }
            } else {
              rowData.push(item[header.value]);
            }
          });
          return rowData;
        }),
      });
      // 创建一个Blob对象并生成下载链接
      const blob = new Blob([new Uint8Array([0xEF, 0xBB, 0xBF]), csv], {type: 'text/csv;charset=utf-8;'});
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = 'export.csv';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      this.searchFlag = false;

    },
    async searchStocks() {
      this.searchFlag = true;
      try {
        let result = await getGoodList({
          jancode: isEmpty(this.janCode) ? null : this.janCode,
          goodName: isEmpty(this.goodName) ? null : this.goodName,
          categoryName: isEmpty(this.categoryName) ? null : this.categoryName,
          brandName: isEmpty(this.brandName) ? null : this.brandName,
          companyName: isEmpty(this.companyName) ? null : this.companyName
        });
        this.searchDate = result.data.result;
      } catch (e) {
        alert("データ取得失敗しました。")
      }
      this.searchFlag = false;
    }
  }
}
</script>

<style scoped>

</style>
