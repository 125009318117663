const namespaced = true;

const defaultData = {
    isShow: false,
    title: "エラーが発生しました",
    contents: "",
    supplementContents: "",
    positiveButtonLabel: "閉じる"
}

const getState = () => ({
    ...defaultData
});

export default {
    namespaced,
    state: getState(),
    mutations: {
        setDialogInfo(state, payload) {
            if (payload.title) state.title = payload.title;
            if (payload.contents) state.contents = payload.contents;
            if (payload.positiveButtonLabel) state.positiveButtonLabel = payload.positiveButtonLabel;
            if (payload.supplementContents) state.supplementContents = payload.supplementContents;
        },
        init(state) {
            defaultData.title = "";
            Object.assign(state, defaultData)
        }
    },
    getters: {
        isShow: state => state.isShow,
        dialogInfo: state => ({
            title: state.title,
            contents: state.contents,
            positiveButtonLabel: state.positiveButtonLabel,
            supplementContents: state.supplementContents
        })
    },
    actions: {
        show(store) {
            store.commit("setIsShow", true);
        },
        setDialogInfo({commit,getters}, {title,contents,supplementContents,positiveButtonLabel}) {
            if(getters.isShow) return;
            commit("setDialogInfo",{
                title,
                contents,
                supplementContents,
                positiveButtonLabel
            })
        },
        refresh({commit}) {
            commit("init");
        }
    }


}