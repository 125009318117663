<template>
  <with-side-menu>
    <v-container fluid>
      <v-row>
        <v-col cols="12">
          <v-row class="ml-3 mb-3">
            <h1>商品新規</h1>
          </v-row>
          <v-row dense>
            <v-col cols="2"></v-col>
            <v-col cols="2">
              <p class="web-title">JANコード<span class="red-asterisk">*</span></p>
            </v-col>
            <v-col cols="4">
              <v-text-field dense outlined v-model="janCode" :rules="rules">
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="2"></v-col>
            <v-col cols="2">
              <p class="web-title">商品名(日文)<span class="red-asterisk">*</span></p>
            </v-col>
            <v-col cols="4">
              <v-text-field dense outlined v-model="nameJP" :rules="rules">
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="2"></v-col>
            <v-col cols="2">
              <p class="web-title">商品名(英文)</p>
            </v-col>
            <v-col cols="4">
              <v-text-field dense outlined v-model="nameEN">
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="2"></v-col>
            <v-col cols="2">
              <p class="web-title">商品名(中文)</p>
            </v-col>
            <v-col cols="4">
              <v-text-field dense outlined v-model="nameCN">
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="2"></v-col>
            <v-col cols="2">
              <p class="web-title">カテゴリ<span class="red-asterisk">*</span></p>
            </v-col>
            <v-col cols="4">
              <v-text-field dense outlined v-model="categoryName" :rules="rules">
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="2"></v-col>
            <v-col cols="2">
              <p class="web-title">ブランド<span class="red-asterisk">*</span></p>
            </v-col>
            <v-col cols="4">
              <v-text-field dense outlined v-model="brandName" :rules="rules">
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="2"></v-col>
            <v-col cols="2">
              <p class="web-title">定価（税抜）<span class="red-asterisk">*</span></p>
            </v-col>
            <v-col cols="4">
              <v-text-field dense outlined v-model="priceExcludingTax" :rules="rules">
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="2"></v-col>
            <v-col cols="2">
              <p class="web-title">税率<span class="red-asterisk">*</span></p>
            </v-col>
            <v-col cols="4">
              <v-select v-model="faxRate"
                        :items="taxRates"
                        label="税率"
                        outlined>
              </v-select>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="2"></v-col>
            <v-col cols="2">
              <p class="web-title">定価（税込）</p>
            </v-col>
            <v-col cols="4">
              <v-text-field dense outlined v-model="priceIncludingTax" :readonly="true">
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="2"></v-col>
            <v-col cols="2">
              <p class="web-title">割引</p>
            </v-col>
            <v-col cols="4">
              <v-text-field dense outlined v-model="discount">
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="2"></v-col>
            <v-col cols="2">
              <p class="web-title">商品画像</p>
            </v-col>
            <v-col cols="4">
              <v-file-input
                  accept=".jpeg,.jpg,.png"
                  @change="selectedFile"
                  v-model="imgUpload"></v-file-input>
            </v-col>
          </v-row>
          <v-row justify="center" align="center">
            <v-col cols="2"></v-col>
            <v-col cols="4">
              <img
                  v-for="(image, index) in inputImage"
                  :key="index"
                  width="200px"
                  height="200px"
                  :src="image.fileUrl"
                  alt="insertGood"/>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="2"></v-col>
            <v-col cols="2">
              <p class="web-title">ケース_入り数<span class="red-asterisk">*</span></p>
            </v-col>
            <v-col cols="4">
              <v-text-field dense outlined v-model="caseQuantity" :rules="rules">
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="2"></v-col>
            <v-col cols="2">
              <p class="web-title">商品説明</p>
            </v-col>
            <v-col cols="4">
              <v-text-field dense outlined v-model="info">
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense v-for="(item, index) in tanSportItems" :key="index">
            <v-col cols="2"></v-col>
            <v-col cols="1">
              <p class="web-title">仕入先{{ index + 1 }}価格 </p>
            </v-col>
            <v-col cols="2">
              <v-text-field dense outlined v-model="item.tanSportPrice">
              </v-text-field>
            </v-col>
            <v-col cols="1">
              <p class="web-title">仕入先 {{ index + 1 }}</p>
            </v-col>
            <v-col cols="2">
              <v-text-field dense outlined v-model="item.tanSportCompanyName">
              </v-text-field>
            </v-col>
            <v-col v-if="index===0" cols="2">
              <v-btn width="120px" class=" white--text button" color="mainColor" @click="addItem" :loading="loading">
                追加
              </v-btn>
            </v-col>
            <v-col v-if="index!==0" cols="2">
              <v-btn width="120px" class=" white--text button" color="red" @click="removeItem(index)">削除</v-btn>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col cols="12" class="text-center">
              <v-btn width="120px" class=" white--text button" color="mainColor" @click="addGood()">登録</v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </with-side-menu>
</template>

<script>
import WithSideMenu from "@/components/WithSideMenu";
import {checkJanCode, insertGood} from "@/api/good";
import store from "@/store";
import {compression, getDataUrl} from "@/utils/image";
import {baseURL} from "@/api/RestClient";
import {isEmpty} from "@/utils/common";
import axios from "axios";

export default {
  name: "AddGood",
  components: {
    WithSideMenu
  },
  data() {
    return {
      nameCN: "",
      nameJP: "",
      nameEN: "",
      priceExcludingTax: 0,
      discount: 0.0,
      pic: "",
      categoryName: "",
      brandName: "",
      caseQuantity: "1",
      janCode: "",
      faxRate: '10%',
      info: "",
      imgUpload: null,
      inputImage: [],
      tanSportItems: [{tanSportCompanyName: '', tanSportPrice: 0}],
      taxRates: ['8%', '10%'],
      loading: false,
      rules: [
        value => {
          if (value) return true
          return '必要項目、入力してください。'
        }
      ]
    }
  },
  computed: {
    priceIncludingTax() {
      if (this.faxRate === "8%") {
        return (Number(this.priceExcludingTax) * 1.08).toFixed(0);
      } else {
        return (Number(this.priceExcludingTax) * 1.1).toFixed(0);
      }
    }
  },
  methods: {
    async selectedFile(file) {
      const newFiles = [];
      if (file != null) {
        newFiles.push(new File([file], file.name, {type: file.type}));
        try {
          // 今まで入力された画像を削除する
          this.inputImage.splice(0, this.inputImage.length);
          newFiles.map(async file => {
            const compressedFile = await compression(file);
            const fileUrl = await getDataUrl(compressedFile);
            this.inputImage.push({
              blob: compressedFile,
              fileUrl,
              fileName: file.name
            });
          });
        } catch (error) {
          console.log(error)
        }
      } else {
        this.inputImage = [];
      }
    },
    addItem() {
      this.tanSportItems.push({tanSportCompanyName: '', tanSportPrice: 0});
    },
    removeItem(index) {
      if (this.tanSportItems.length > 1) {
        this.tanSportItems.splice(index, 1);
      }
    },
    async addGood() {
      this.loading = true;
      if (isEmpty(this.nameJP) || isEmpty(this.priceExcludingTax) || isEmpty(this.categoryName)
          || isEmpty(this.brandName) || isEmpty(this.caseQuantity) || isEmpty(this.janCode)
          || isEmpty(this.faxRate)) {
        alert("必要な情報を入力してください。");
        return;
      }

      try {
        const response = await checkJanCode({jancode: this.janCode});
        if (response.data.result) {
          alert("jancodeすでに存在されました。検索して、更新してください。")
          return;
        }
      } catch (e) {
        alert("jancode判定失敗しました。")
        this.loading = false;
        return;
      }


      if (this.imgUpload != null) {
        //imageupload
        try {
          const file = this.imgUpload;
          let formData = new FormData();
          formData.append('file', file);
          const headers = {
            'token': store.state.userInfo.accessToken,
          };
          const res = await axios.post(baseURL + "author/ImageUpload", formData, {headers})
          this.pic = res.data;
        } catch (error) {
          console.log(error)
        }
      }
      try {
        await insertGood({
          nameJp: this.nameJP,
          nameEn: this.nameEN,
          nameCn: this.nameCN,
          priceExcludingTax: this.priceExcludingTax.toString(),
          priceIncludingTax: this.priceIncludingTax.toString(),
          discount: this.discount.toString(),
          pic: this.pic,
          categoryName: this.categoryName,
          brandName: this.brandName,
          caseQuantity: this.caseQuantity.toString(),
          jancode: this.janCode,
          faxRate: this.faxRate === '8%' ? '8' : '10',
          info: this.info,
          tansportDetail: JSON.stringify(this.tanSportItems),
          ceateName: store.state.userInfo.userName
        })
        alert("商品新規成功しました。");
        location.reload();
      } catch (e) {
        alert("商品新規失敗しました。");
      }
      this.loading = false;
    }
  }
}
</script>

<style scoped>
.web-title {
  font-size: 18px;
}

.red-asterisk {
  color: red;
}
</style>
